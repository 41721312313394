var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"asset-uploader-cropper"},[_c('div',{ref:("cropper-wrapper-" + _vm.id),staticClass:"cropper-wrapper",on:{"mousedown":_vm.startOffsetDrag,"mousemove":_vm.calculateOffset,"mouseup":_vm.stopOffsetDrag,"mouseout":_vm.stopOffsetDrag}},[_c('div',{ref:("cropper-offset-" + _vm.id),staticClass:"cropper-offset",style:({
				top: (_vm.localOffsetY + "px"),
				left: (_vm.localOffsetX + "px"),
			})},[_c('div',{ref:("cropper-scale-" + _vm.id),staticClass:"cropper-scale",style:({
					transform: ("scale(" + _vm.localScale + ")"),
				})},[_vm._t("default")],2)])]),_c('canvas',{ref:("cropper-canvas-" + _vm.id),staticClass:"cropper-canvas",style:({
			width: (_vm.width + "px"),
			height: (_vm.height + "px"),
		})})])}
var staticRenderFns = []

export { render, staticRenderFns }