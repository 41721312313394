import { MutationTree } from "vuex";

import { SessionState } from "./types";
import { UserObject } from "@scrinz/dtos";
import { Ability } from "@casl/ability";

// tslint:disable:naming-convention
export const mutations: MutationTree<SessionState> = {
	RESET_STATE(state) {
		state.ability = null;
		state.token = null;
		state.tokenUser = null;
		// state.userId = null;
	},

	SET_ABILITY(state, ability: Ability) {
		state.ability = ability;
	},

	SET_TOKEN(state, token: string | null) {
		state.token = token;
	},

	SET_TOKEN_USER(state, user: UserObject) {
		state.tokenUser = user;
	},

	// SET_TOKEN_USER_ID(state, id: number | null) {
	// 	state.userId = id;
	// },
};
