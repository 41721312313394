import { Field, InputType } from "@nestjs/graphql";
import { IsEmail, IsEnum } from "class-validator";

import { BaseDto } from "../..";
import { UserRoleEnum } from "../../user";

@InputType()
export class AddMemberInput extends BaseDto {
	@Field(() => String)
	@IsEmail()
	email!: string;

	@Field(() => UserRoleEnum)
	@IsEnum(UserRoleEnum)
	role!: UserRoleEnum;
}
