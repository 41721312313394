import { ActionTree } from "vuex";

import { EntityInterface } from "@scrinz/dtos";
import http from "@/http";
import { RootState } from "@/store";
import { AutoApproveNumber, AutoApproveNumbersState } from "./types";

export const actions: ActionTree<AutoApproveNumbersState, RootState> = {
	async fetchAutoApproveNumbers({ commit }, entity: EntityInterface) {
		const res = await http.get(`entities/${entity.id}/auto-approve-numbers`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to load auto-approve numbers.`);
		}

		commit("UPDATE_AUTO_APPROVE_NUMBERS", res.data);
	},

	async createAutoApproveNumber({ commit }, num: AutoApproveNumber) {
		const res = await http.post(
			`/entities/${num.entityId}/auto-approve-numbers`,
			num,
		);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to create auto-approve number.`);
		}

		// Add newly created flag.
		res.data.newlyCreated = true;

		commit("ADD_AUTO_APPROVE_NUMBER", res.data);
	},

	async updateAutoApproveNumber({ commit }, num: AutoApproveNumber) {
		const res = await http.put(
			`/entities/${num.entityId}/auto-approve-numbers/${num.id}`,
			num,
		);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update anon with id "${num.id}".`);
		}

		// Add newly updated flag.
		res.data.newlyUpdated = true;

		commit("UPDATE_AUTO_APPROVE_NUMBER", res.data);
	},

	async deleteAutoApproveNumber({ commit }, num: AutoApproveNumber) {
		const res = await http.delete(
			`/entities/${num.entityId}/auto-approve-numbers/${num.id}`,
		);

		if (!res || res.status !== 200) {
			throw new Error(
				`Failed to delete auto-approve number with id "${num.id}".`,
			);
		}

		commit("DELETE_AUTO_APPROVE_NUMBER", num);
	},
};
