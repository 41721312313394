var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('admin'))?_c('sz-dialog-card-form',{attrs:{"title":_vm.$t('New entity'),"data":_vm.entity,"submit":_vm.submit,"max-width":400,"fields":[
		{ key: 'name', label: _vm.$t('Name') },
		{
			key: 'type',
			label: _vm.$t('Type'),
			type: 'select',
			options: [
				{ value: _vm.types.Organization, text: _vm.$t('Organization') },
				{ value: _vm.types.Group, text: _vm.$t('Group') },
				{ value: _vm.types.Display, text: _vm.$t('Display') } ],
		} ]},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }