

































































































































































































import clone from "lodash/clone";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { quillEditor } from "vue-quill-editor";
import Nl2Br from "vue-nl2br";

import { SdBannerContainer } from "@scrinz/components";
import { EntityInterface } from "@scrinz/dtos";

import { TEMPLATES } from "@/assets/templates";
import AssetUploader from "@/components/AssetUploader";
import http from "@/http";

import TemplateCategorySelector from "./TemplateCategorySelector.vue";
import { FEATURE_SET } from "../../features";

export enum TemplateType {
	None = 0,
	Asset = 1,
	Template = 2,
}

@Component({
	components: {
		AssetUploader,
		quillEditor,
		nl2br: Nl2Br,
		TemplateCategorySelector,
		SdBannerContainer,
	},
})
export default class EditBannerDialog extends Vue {
	templates = TEMPLATES;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [Object] })
	banner!: any;

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true })
	slotId!: string;

	@Prop()
	slotWidth!: number;

	@Prop()
	slotHeight!: number;

	item: any | null = null;
	type: TemplateType = TemplateType.None;

	selectedTemplateCategory: string | null = null;
	templatePickerSelectedTab: number | null = null;

	minDate = new Date();
	maxDate = new Date(
		new Date().setFullYear(this.minDate.getFullYear() + 1).valueOf(),
	);

	created() {
		if (FEATURE_SET === "varig") this.setType(TemplateType.Asset);
	}

	get filteredTemplates() {
		if (!this.templates) return [];
		if (this.selectedTemplateCategory === null) return this.templates;

		return this.templates.filter(
			(t) => t.category === this.selectedTemplateCategory,
		);
	}

	get isUntyped() {
		return this.type === TemplateType.None;
	}

	get isAsset() {
		return this.type === TemplateType.Asset;
	}

	get isTemplate() {
		return this.type === TemplateType.Template;
	}

	get open() {
		return this.value;
	}

	set open(value: boolean) {
		this.$emit("input", value);
	}

	@Watch("banner", { deep: true, immediate: true })
	onBannerChange() {
		this.item = clone(this.banner);
		this.type = this.item.type || TemplateType.None;
	}

	onTemplateCategoryChange(category: null | string) {
		this.selectedTemplateCategory = category;
	}

	getTemplateById(id: string): any {
		return this.templates.find((t) => t.id === id);
	}

	getTemplateTextClassById(id: string) {
		const template = this.getTemplateById(id);

		if (!template) return {};

		return {
			"template-template__text--dark": template.fontColor === "dark",
			"template-template__text--light":
				template.fontColor === "light" || !template.fontColor,
		};
	}

	async setType(
		type: TemplateType,
		templateId: string | undefined = undefined,
	) {
		this.type = type;
		this.item.type = type;
		this.item.template = templateId;
	}

	async save() {
		if (this.item.type === TemplateType.Asset) {
			const assetUploader = this.$refs["asset-uploader"] as any;

			await assetUploader.save();
		}

		await this.$store.dispatch(
			this.item.id ? "updateContent" : "createContent",
			{
				entity: this.entity,
				slot: this.slotId,
				content: this.item,
			},
		);

		this.open = false;
		this.$emit("update");
	}

	async remove() {
		const confirmed = await this.$confirm(this.$t(`confirmDelete.text`), {
			color: "error",
			title: this.$t(`confirmDelete.title`),
		});

		if (!confirmed) return;

		await this.$store.dispatch("deleteContent", {
			entity: this.entity,
			slot: this.slotId,
			content: this.item,
		});

		this.open = false;
		this.$emit("update");
	}

	/**
	 * This needs to be reworked
	 * =========================
	 */

	bannerTemplates: any[] = [];

	async saveAsTemplate() {
		this.item.isTemplate = true;

		await this.save();
		await this.updateBannerTemplates();
	}

	setTemplate(t: any) {
		const { template, templateText, type } = t;
		this.item = { ...this.banner, template, templateText, type };
		this.type = type;
	}

	@Watch("entity", { immediate: true })
	async updateBannerTemplates(): Promise<void> {
		try {
			const res = await http.get(
				`/content/${this.entity.id}/banner?isTemplate=true`,
			);

			if (res && res.data && res.data instanceof Array) {
				this.bannerTemplates = res.data;
			}
		} catch (e) {
			// Do nothing.
		}
	}
}
