import Vue from "vue";
import VueAnalytics from "vue-analytics";
import VueRouter from "vue-router";

export interface AnalyticsOptions {
	router: VueRouter;
}

const isProd = process.env.NODE_ENV === "production";
const analyticsId = process.env.VUE_APP_ANALYTICS_ID;

export default function registerAnalytics(options: AnalyticsOptions) {
	const { router } = options;

	if (!analyticsId) {
		return;
	}

	Vue.use(VueAnalytics, {
		router,
		id: analyticsId,

		debug: {
			enabled: !isProd,
			sendHitTask: isProd,
		},

		autoTracking: {
			screenview: true,
		},
	});
}
