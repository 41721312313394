
























import Vue from "vue";
import { Component } from "vue-property-decorator";

import LayoutFluid from "@/layouts/Fluid.vue";

import ClientSettingsLanguage from "./Settings/Language.vue";
import ClientSettingsPassword from "./Settings/Password.vue";
import ClientSettingsProfile from "./Settings/Profile.vue";

@Component({
	title: "Settings",
	components: {
		ClientSettingsLanguage,
		ClientSettingsPassword,
		ClientSettingsProfile,
		LayoutFluid,
	},
})
export default class ClientSettings extends Vue {}
