import { Field, Int, ObjectType } from "@nestjs/graphql";

@ObjectType("User")
export class UserObject {
	@Field(() => Boolean, { nullable: true })
	admin?: boolean;

	@Field(() => Int)
	id!: number;

	@Field(() => String)
	email!: string;
}
