





























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class BannerEditorSlotPopup extends Vue {
	@Prop({ required: true })
	banner!: any;

	@Prop()
	asset!: any;

	@Prop()
	imageUrl!: string;

	@Prop()
	open!: boolean;

	get classes() {
		return {
			"banner-editor__slot__popup": true,
			"banner-editor__slot__popup--closed": !this.open,
			"banner-editor__slot__popup--open": this.open,
		};
	}
}
