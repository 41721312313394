


























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Colorable from "vuetify/es5/mixins/colorable";
import Themeable from "vuetify/es5/mixins/themeable";

interface DynamicClassList {
	[className: string]: boolean;
}

@Component({
	mixins: [Colorable, Themeable],
})
export default class SzEmptyState extends Vue {
	@Prop()
	description!: string;

	@Prop({ type: [Boolean] })
	horizontal!: boolean;

	@Prop()
	icon!: string;

	@Prop({ default: "secondary" })
	iconColor!: string;

	@Prop({ default: 160 })
	iconSize!: number;

	@Prop()
	label!: string;

	@Prop({ type: [Boolean] })
	vertical!: boolean;

	themeClasses!: DynamicClassList;

	get classes() {
		return {
			"sz-empty-state": true,
			"sz-empty-state--horizontal": this.horizontal || !this.vertical,
			"sz-empty-state--vertical": !this.horizontal && this.vertical,
			...this.themeClasses,
		};
	}

	get hasActions() {
		return !!this.$slots["actions"];
	}

	get hasContents() {
		return !!this.$slots["default"];
	}

	get hasDescription() {
		return !!this.description;
	}

	get hasContentsOrDescription() {
		return this.hasContents || this.hasDescription;
	}
}
