import { IsEmail, IsOptional, IsString } from "class-validator";

import { BaseDto } from "..";

export class ContactEmailDto extends BaseDto {
	@IsEmail() email!: string;

	@IsString() name!: string;

	@IsOptional()
	@IsString()
	subject!: string;

	@IsString() message!: string;
}
