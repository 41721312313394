import { SdNoteIconsIcons } from "./interfaces";

import { SD_MATERIAL_ICON_LIGATURE_MAP } from "../SdMaterialIcon";

export const SD_NOTE_ICON_ICONS: SdNoteIconsIcons = {
	i: {
		type: "font",
		content: "i",
	},
};

for (const materialIcon in SD_MATERIAL_ICON_LIGATURE_MAP) {
	SD_NOTE_ICON_ICONS[materialIcon] = {
		type: "icon",
		content: materialIcon,
	};
}
