import { Field, ObjectType } from "@nestjs/graphql";
import { ContentInterface } from "../content";

@ObjectType("DisplayManifestFallback")
export class DisplayManifestFallbackObject {
	@Field(() => String)
	key!: string;

	@Field(() => [ContentInterface])
	content!: ContentInterface[];
}
