
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AdminUserDetailsDetails extends Vue {
	@Prop()
	userId!: number;

	user!: any;

	created() {
		this.user = this.$store.getters.userById(this.userId, 10);
	}

	async submit(data: any) {
		return this.$store.dispatch("updateUser", data);
	}
}
