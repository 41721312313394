import Vue from "vue";

import store from "@/store";

// Checks if there is a token set, and send to application if so.
export async function loginRedirect(vm: Vue) {
	const routeName = vm.$route.name || null;

	// If no token return empty.
	if (!store.getters.token) return;

	// If path is reset password confirmation or logout set token to `null`.
	if (
		["auth--reset-password-confirm", "auth--logout"].includes(routeName || "")
	) {
		await store.dispatch("setToken", null);
	}

	let path: string | string[];

	// Use `return` query param if present.
	if (vm.$route.query.return) {
		path = vm.$route.query.return as string | string[];
	}

	// Else, use `fullPath` if not on login or logout route.
	else if (!["auth--login", "auth--logout"].includes(routeName || "")) {
		path = vm.$route.fullPath;
	}

	// Else, fall back to root path
	else {
		path = "/";
	}

	// If path is an array, pick first in list.
	if (path instanceof Array) {
		path = path[0];
	}

	vm.$router.replace(path);
}
