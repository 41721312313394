import { MutationTree } from "vuex";

import { AutoApproveNumber, AutoApproveNumbersState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<AutoApproveNumbersState> = {
	RESET_STATE(state) {
		state.autoApproveNumbers = [];
	},

	ADD_AUTO_APPROVE_NUMBER(state, num: AutoApproveNumber) {
		state.autoApproveNumbers.push(num);
	},

	DELETE_AUTO_APPROVE_NUMBER(state, num: AutoApproveNumber) {
		const idx = state.autoApproveNumbers.findIndex((u) => u.id === num.id);

		if (idx === -1) return;

		state.autoApproveNumbers.splice(idx, 1);
	},

	UPDATE_AUTO_APPROVE_NUMBER(state, num: AutoApproveNumber) {
		const current = state.autoApproveNumbers.find((u) => u.id === num.id);

		if (!current) return;

		Object.assign(current, num);
	},

	UPDATE_AUTO_APPROVE_NUMBERS(state, autoApproveNumbers: AutoApproveNumber[]) {
		for (const num of autoApproveNumbers) {
			const current = state.autoApproveNumbers.find((u) => u.id === num.id);

			if (current) {
				Object.assign(current, num);
			} else {
				state.autoApproveNumbers.push(num);
			}
		}
	},
};
