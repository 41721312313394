import Vue, { PluginObject } from "vue";

import {
	decodeHashid,
	encodeHashid,
	entityUtils,
	roleUtils,
} from "@scrinz/helpers";

// Object with helpers to be made available in Vue component instances.
const SCRINZ_HELPERS = {
	...entityUtils,
	...roleUtils,

	hashid: {
		decode: decodeHashid,
		encode: encodeHashid,
	},
};

// The Vue plugin for Scrinz related code.
export const ScrinzVuePlugin: PluginObject<undefined> = {
	install: (V) => {
		// Setup Vue component instance property.
		V.prototype.$scrinz = SCRINZ_HELPERS;
	},
};

// Type definition of helpers.
export type ScrinzHelpersType = typeof SCRINZ_HELPERS;

export interface ScrinzObject extends Vue, ScrinzHelpersType {}

// Declare module for proper typing of the Scrinz Vue plugin.
declare module "vue/types/vue" {
	export interface Vue {
		$scrinz: ScrinzObject;
	}
}
