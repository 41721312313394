


































import { Component, Prop, Vue } from "vue-property-decorator";
import getenv from "getenv.ts";

import { DisplayManifestObject } from "@scrinz/dtos";

@Component
export default class SdNoteBoardInfo extends Vue {
	@Prop({ required: true, type: [Object] })
	manifest!: DisplayManifestObject;

	@Prop({ default: "default" })
	logo!: string;

	get smsNumber() {
		return getenv.string("VUE_APP_SMS_NUMBER");
	}
}
