





























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { entityCanHaveChildren } from "@scrinz/helpers";

@Component
export default class EntityDeleteDialog extends Vue {
	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	confirmDelete = "";
	withChildren: "delete" | "move" | null = null;
	confirmDeleteChildren = false;
	moveTo: string | null = null;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.confirmDelete = "";
		this.withChildren = null;
		this.confirmDeleteChildren = false;
		this.moveTo = null;

		this.$emit("input", value);
	}

	get confirmationIsValid() {
		if (this.entity.name !== this.confirmDelete) return false;

		if (this.hasChildren) {
			if (this.withChildren === "delete" && this.confirmDeleteChildren) {
				return true;
			}
			if (this.withChildren === "move" && this.moveTo !== null) return true;

			return false;
		}

		return true;
	}

	get children(): EntityInterface[] {
		return this.$store.getters.getChildrenOfEntity(this.entity);
	}

	get hasChildren() {
		return this.children.length > 0;
	}

	get moveToEntities() {
		const childIds = this.children.map((e) => e.id);

		return this.$store.getters.entitiesInContext
			.filter((e: EntityInterface) => e.id !== this.entity.id)
			.filter((e: EntityInterface) => !childIds.includes(e.id))
			.filter(entityCanHaveChildren);
	}

	async deleteEntity() {
		if (!this.confirmationIsValid) return;

		await this.$store.dispatch("deleteEntity", {
			entity: this.entity,
			withChildren: this.withChildren,
			moveTo: this.moveTo,
		});

		this.$router.push("/entities");
	}
}
