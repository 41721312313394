









import { Component, Prop, Vue } from "vue-property-decorator";

import { SD_MATERIAL_ICON_LIGATURE_MAP } from "./constants";

@Component
export default class SdMaterialIcon extends Vue {
	/**
	 * The name of the icon as defined by the Material Icon font.
	 */
	@Prop({ required: true })
	name!: string;

	/**
	 * The font size to display the icon in.
	 */
	@Prop({ default: null })
	size!: number | null;

	get numeric() {
		return `&#x${SD_MATERIAL_ICON_LIGATURE_MAP[this.name]};`;
	}

	get style() {
		return this.size && { fontSize: `${this.size}px` };
	}
}
