import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { ServicesState } from "./types";
import { EntityInterface } from "@scrinz/dtos";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<ServicesState, RootState> = {
	services(state) {
		return state.services;
	},

	servicesFor(state, getters) {
		return (entity: EntityInterface) => {
			const ids = getters.getEntityChainIds(entity) as number[];

			return state.services.service.filter((service) =>
				ids.includes(service.entityId),
			);
		};
	},
};
