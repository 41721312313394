import { Field, InputType } from "@nestjs/graphql";

@InputType()
export class AddContentInput {
	@Field(() => String, { nullable: true })
	slot!: string | null;

	@Field(() => Date, { nullable: true })
	start!: Date | null;

	@Field(() => Date, { nullable: true })
	end!: Date | null;
}
