
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { SdSpinnerIndeterminate } from "../SdSpinnerIndeterminate";

enum ImageStatus {
	Pending = "pending",
	Loading = "loading",
	Loaded = "loaded",
	Failed = "failed",
}

@Component({
	components: { SdSpinnerIndeterminate },
})
export default class SdImageLoader extends Vue {
	/**
	 * Property for setting the source address for image to load.
	 */
	@Prop({ required: true, type: String })
	src!: string;

	/**
	 * Boolean property for setting whether to automatically retry for failed
	 * loading of images.
	 */
	@Prop({ default: true, type: Boolean })
	autoRetry!: boolean;

	/**
	 * Number in milliseconds defining a waiting period before auto retrying.
	 */
	@Prop({ default: 5000, type: Number })
	autoRetryTimeout!: number;

	/**
	 * The current status of the loading process.
	 */
	status: ImageStatus = ImageStatus.Pending;

	/**
	 * Internal reference to timeout, so it can be cleared.
	 */
	private _retryTimeout?: number;

	/**
	 * Start loading image when `mounted` lifecycle hook is called.
	 */
	mounted() {
		this.loadImage(this.src);
	}

	/**
	 * Defines the image element, attaching event listeners and starting loading
	 * of provided src address.
	 *
	 * @param string The image address to load
	 */
	@Watch("src")
	loadImage(src: string) {
		// Don't do anything if src is empty
		if (!src) return;

		// Clear retry timeout
		clearTimeout(this._retryTimeout);

		// Update status to loading
		this.status = ImageStatus.Loading;

		// Get reference to image element
		const img = this.$refs.image as HTMLImageElement;

		// Attach event handlers
		img.onload = this._onLoad;
		img.onerror = this._onError;

		// Set parameters on image element
		img.crossOrigin = "anonymous";
		img.decoding = "async";

		// Start loading by setting src
		img.src = src;
	}

	/**
	 * Method for retrying to load image if failed.
	 */
	retry() {
		if (this.status === ImageStatus.Failed) {
			this.loadImage(this.src);
		}
	}

	/**
	 * Handler for `onload` event triggered by sucessful loading of image.
	 */
	private _onLoad() {
		this.status = ImageStatus.Loaded;
		this.$emit("loaded");
	}

	/**
	 * Handler for `onerror` event triggered by failed loading of image.
	 */
	private _onError(event: Event | string) {
		this.status = ImageStatus.Failed;
		this.$emit("error", event);

		// Retry if `autoRetry` property is true.
		if (this.autoRetry === true) {
			this._retryTimeout = window.setTimeout(
				this.retry.bind(this),
				this.autoRetryTimeout
			);
		}
	}
}
