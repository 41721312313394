import {
	DisplayEntityObject,
	EntityInterface,
	EntityTypeEnum,
} from "@scrinz/dtos";

export function isDisplay(entity: unknown): entity is DisplayEntityObject {
	return (
		entity instanceof EntityInterface && entity.type === EntityTypeEnum.Display
	);
}
