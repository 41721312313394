import { Field, Int, ObjectType } from "@nestjs/graphql";

import { BaseDto, UserRoleEnum } from "..";

@ObjectType("EntityUser")
export class EntityUserObject extends BaseDto {
	@Field(() => Int)
	userId!: number;

	@Field(() => String)
	email!: string;

	@Field(() => UserRoleEnum)
	role!: UserRoleEnum;
}
