







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";

import { AutoApproveNumbersCard } from "@/components/AutoApproveNumbers";
import { UsersCard } from "@/components/Users";

@Component<ClientEntityDetailsMembers>({
	title: vm => ["Members", vm.entity.name],
	components: {
		AutoApproveNumbersCard,
		UsersCard,
	},
})
export default class ClientEntityDetailsMembers extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity(): EntityInterface {
		return this.$store.getters.getEntity(this.id) as EntityInterface;
	}
}
