import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { ContentState } from "./types";
import { EntityInterface } from "@scrinz/dtos";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<ContentState, RootState> = {
	templates(state) {
		return state.slots.template;
	},

	contentsFor(state, getters) {
		return (entity: EntityInterface, slot: string) => {
			if (!state.slots[slot]) return [];

			const ids = getters.getEntityChainIds(entity) as number[];

			return state.slots[slot].filter((content) =>
				ids.includes(content.entityId),
			);
		};
	},
};
