












































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

function dateToIso8601Date(date: Date) {
	return moment(date).format("YYYY-MM-DD");
}

@Component
export default class SzDatePickerInput extends Vue {
	@Prop({ type: [Date, Object] })
	value!: Date | undefined;

	@Prop()
	label!: string;

	@Prop()
	prependIcon!: string;

	@Prop({ default: false })
	clearable!: boolean;

	@Prop()
	minDate!: Date;

	@Prop()
	maxDate!: Date;

	date = "";
	showMenu = false;

	get min() {
		return this.minDate && dateToIso8601Date(this.minDate);
	}

	get max() {
		return this.maxDate && dateToIso8601Date(this.maxDate);
	}

	get nudgeRight() {
		return this.prependIcon && "33";
	}

	get locale() {
		if (this.$i18n.locale === "no") return "nb";

		return this.$i18n.locale;
	}

	formatDateLocale(date: string, options: Intl.DateTimeFormatOptions): string {
		return new Intl.DateTimeFormat(
			this.locale,
			options
			).format(new Date(date));
	}

	formatHeader(date: string): string {
		if (String(date).split("-")[1]) {
			const newDate = this.formatDateLocale(date, { month: "long", year: "numeric" });

			return newDate[0].toUpperCase() + newDate.slice(1);
		}

		return this.formatDateLocale(date, { year: "numeric" });
	}

	formatDateTable(date: string): string {
		const newDate = this.formatDateLocale(date, { day: "numeric" });

		if (newDate.slice(-1) === ".") {
			return newDate.replace(".", "");
		}

		return newDate;
	}

	@Watch("date")
	onDateChanged() {
		if (!this.date) {
			this.$emit("input", undefined);

			return;
		}

		const value = this.value && new Date(this.value) || new Date();
		const newDate = new Date(this.date);

		value.setFullYear(newDate.getFullYear());
		value.setMonth(newDate.getMonth());
		value.setDate(newDate.getDate());

		this.$emit("input", value);
	}

	@Watch("value", { immediate: true })
	onValueChanged() {
		if (!(this.value instanceof Date)) return;

		const date = dateToIso8601Date(this.value);

		if (this.date !== date) {
			this.date = date;
		}
	}
}
