













import { Component, Prop, Vue } from "vue-property-decorator";

import { ContentSlotEnum } from "@scrinz/dtos";

import BannerEditor from "@/components/BannerEditor";
import { getFeature } from "@/features";
import { ScrinzAppBannerFeature } from "@/features/interfaces";

const SLOTS: {
	[id: string]: {
		title: string;
		type: ContentSlotEnum;
	};
} = {
	banner: {
		title: "Banner",
		type: ContentSlotEnum.BANNER,
	},
	partnerLogo: {
		title: "Partner Logo",
		type: ContentSlotEnum.BANNER,
	},
};

@Component<ClientEntityDetailsContent>({
	title: (vm) => ["Content", vm.contentSlot?.title, vm.entity.name],
	components: { BannerEditor },
})
export default class ClientEntityDetailsContent extends Vue {
	@Prop({ required: true })
	id!: string;

	@Prop({ required: true })
	contentSlotId!: string;

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}

	get contentSlot() {
		return SLOTS[this.contentSlotId];
	}

	get featureConfig(): ScrinzAppBannerFeature | false | void {
		return getFeature(this.contentSlotId);
	}
}
