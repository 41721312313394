




import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class DisplayOnlineStatus extends Vue {
	@Prop({ required: true })
	display!: any;

	get isRegistered() {
		return !!this.display.registrationHandle;
	}

	get isOnline() {
		return !!this.display.onlineStatus;
	}

	get isOffline() {
		return this.isRegistered && !this.isOnline;
	}

	get classes() {
		return {
			"display-online-status": true,
			"display-online-status--online": this.isOnline,
			"display-online-status--offline": this.isOffline,
		};
	}
}
