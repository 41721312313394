
















import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
	title: "Profile Settings",
})
export default class ClientSettingsProfile extends Vue {
	get user() {
		return this.$store.getters.user;
	}

	async submit(data: any) {
		return this.$store.dispatch("updateUser", data);
	}
}
