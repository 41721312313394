









import { Component, Prop, Vue } from "vue-property-decorator";

import SdMaterialIcon from "../SdMaterialIcon";

import { SD_NOTE_ICON_ICONS } from "./constants";

@Component({
	components: { SdMaterialIcon },
})
export default class SdNoteIcon extends Vue {
	/**
	 * The name of the icon to display, as defined by Material Icons.
	 */
	@Prop({ required: true })
	name!: string;

	/**
	 * The background color to display the icon on top.
	 */
	@Prop({ default: null })
	backgroundColor!: string | null;

	get isFont() {
		return this.type === "font";
	}

	get type() {
		return this._icon.type;
	}

	get content() {
		return this._icon.content;
	}

	get contentClass() {
		const classes: any = {
			"sd-note-icon__content": true,
		};

		classes[`sd-note-icon__content--${this.type}`] = true;

		return classes;
	}

	get iconStyle() {
		const styles: any = {};

		if (this.backgroundColor) {
			styles.background = this.backgroundColor;
		}

		return styles;
	}

	private get _icon() {
		return this.name && SD_NOTE_ICON_ICONS[this.name]
			|| { type: "icon", content: this.name };
	}
}
