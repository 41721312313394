






























import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { sortEntitiesByNaturalName, sortEntitiesByType } from "@scrinz/helpers";

import store from "@/store";
import EntityListItem from "./ListItem.vue";

@Component({
	name: "entity-list",
	components: { EntityListItem },
})
export default class EntityList extends Vue {
	@Prop()
	entity!: EntityInterface;

	@Prop({ default: false, type: [Boolean, Number] })
	droppable!: boolean | number;

	@Prop({ type: [String, Number] })
	parent!: number;

	@Prop({ default: true })
	enableInteraction!: boolean;

	@Prop()
	sortBy!: string;

	@Prop({ default: "ASC" })
	sortDirection!: string;

	dragging: boolean | number = false;
	dragHover: boolean | number = false;

	get entities() {
		if (!this.entity) {
			return [store.getters.entityContext];
		}

		return store.getters.getChildrenOfEntity(this.entity);
	}

	get sortedEntities() {
		let entities: EntityInterface[] = this.entities;

		switch (this.sortBy) {
			case "name":
				entities = sortEntitiesByNaturalName(entities);
				break;
			case "type":
				entities = sortEntitiesByType(entities);
				break;
		}

		return this.sortDirection.toUpperCase() === "DESC"
			? entities.reverse()
			: entities;
	}

	hasChildren(entity: EntityInterface) {
		return store.getters.getChildrenOfEntity(entity).length > 0;
	}

	isDragOver(entity: EntityInterface) {
		return this.dragHover === entity.id;
	}

	isDraggable(_entity: EntityInterface) {
		return this.$can("admin") && this.enableInteraction && !!this.parent;
	}

	isDroppable(entity: EntityInterface) {
		return (
			this.$can("admin") &&
			this.enableInteraction &&
			this.dragging !== entity.id &&
			(this.droppable || this.dragging)
		);
	}

	onChildDragging(value: number) {
		this.dragging = value;
		this.$emit("dragging", value);
	}

	onItemDragging(value: number) {
		this.dragging = value;
		this.$emit("dragging", value);
	}

	onItemDropped(_value: EntityInterface) {
		// console.log("dropped", this.entities[0].name, value);
	}
}
