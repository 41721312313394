var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"entity-list-item",class:{
		dragging: _vm.dragging,
		hover: _vm.hover,
		interaction: _vm.enableInteraction,
		'newly-created': _vm.entity.newlyCreated,
		'newly-updated': _vm.entity.newlyUpdated,
	},attrs:{"draggable":_vm.enableDrag,"dropzone":_vm.droppable},on:{"dragstart":_vm.onDragStart,"dragend":_vm.onDragEnd,"dragover":_vm.onDragOver,"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"drop":_vm.onDrop}},[_c('v-layout',{staticClass:"entity-list-item--current",attrs:{"row":"","align-center":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleChildren.apply(null, arguments)}}},[_c('v-icon',{staticClass:"entity-list-item--current--icon",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$scrinz.getIconForEntityType(_vm.entity.type))+" ")]),_c('router-link',{staticClass:"entity-list-item--current--link",attrs:{"to":("/entities/" + (_vm.entity.id))}},[_vm._v(" "+_vm._s(_vm.entity.name)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [(
						_vm.enableInteraction &&
						_vm.$can('create', 'Entity') &&
						_vm.$scrinz.entityCanHaveChildren(_vm.entity)
					)?_c('v-btn',_vm._g({staticClass:"entity-list-item--current--add-new-button",attrs:{"icon":"","small":""},on:{"click":_vm.openAddEntityDialog}},on),[_c('v-icon',[_vm._v("add")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Create new entity under {0}", [_vm.entity.name])))])]),_c('v-spacer'),(_vm.$scrinz.entityCanHaveChildren(_vm.entity))?_c('div',{staticClass:"collapse-icon"},[(!_vm.showChildren)?_c('v-icon',{staticClass:"entity-list-item--current--expand-group-icon"},[_vm._v("expand_more")]):_vm._e(),(_vm.showChildren)?_c('v-icon',{staticClass:"entity-list-item--current--expand-group-icon"},[_vm._v("expand_less")]):_vm._e()],1):_vm._e()],1),(_vm.showChildren)?_c('div',[_vm._t("default",null,{"children":{ dragging: _vm.dragging, droppable: _vm.droppable && !_vm.dragging }})],2):_vm._e(),_c('div',{staticClass:"drag-image-container"},[_c('div',{ref:"drag-image-wrapper",staticClass:"drag-image-wrapper"})]),_c('entity-add-dialog',{attrs:{"parent-entity":_vm.entity},model:{value:(_vm.showAddDialog),callback:function ($$v) {_vm.showAddDialog=$$v},expression:"showAddDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }