import { Field, ObjectType } from "@nestjs/graphql";
import { AssetObject } from "../assets";
import { ContentBannerInterface } from "./content-banner.interface";
import { ContentInterface } from "./content.interface";

@ObjectType("ContentBannerAsset", {
	implements: [ContentInterface, ContentBannerInterface],
})
export class ContentBannerAssetObject extends ContentBannerInterface {
	@Field(() => AssetObject)
	asset!: AssetObject;
}
