var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sz-dialog-card-form',{attrs:{"max-width":400,"data":_vm.item,"submit":_vm.submit,"title":_vm.$t(
			("components.auto-approve-numbers.edit-dialog." + (_vm.item && _vm.item.id ? "editTitle" : "newTitle"))
		),"fields":[
		{ key: 'number', label: _vm.$t("Number"), type: 'tel' },
		{ key: 'description', label: _vm.$t("Description") },
		{
			key: 'expirationDate',
			label: _vm.$t("Expiration Date"),
			type: 'date',
			icon: 'calendar_today',
		} ]},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})}
var staticRenderFns = []

export { render, staticRenderFns }