







































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import clone from "lodash/clone";

import i18n from "@/plugins/i18n";

export enum FormState {
	Uninitialized,
	Initialized,
	Submitting,
	Success,
	Error,
}

export interface FormField {
	$can?: string;
	key: string;
	label: string;
	type: string;
	icon?: string;
	palette?: string;
	options?: string[] | { value: number | string; text: string }[];
}

@Component
export default class SzCardForm extends Vue {
	@Prop({ default: () => ({}) })
	data!: any;

	@Prop()
	title!: string;

	@Prop()
	errorMessage!: string;

	@Prop()
	successMessage!: string;

	@Prop({ required: true, type: [Function] })
	submit!: any;

	@Prop({ default: i18n.t("Update") })
	submitText!: string;

	@Prop({ type: [Function] })
	cancel!: any;

	@Prop({ default: i18n.t("Cancel") })
	cancelText!: string;

	@Prop({ required: true, type: [Array] })
	fields!: FormField[];

	state: FormState = FormState.Uninitialized;
	formData: any = {};

	showResponse = false;
	showResponseType: "success" | "error" | null = null;
	showResponseTimeout: number | null = null;

	get fieldsWithAccess() {
		return this.fields.filter((field) => this.hasRightsTo(field));
	}

	get hasToolbar() {
		return !!this.$slots.toolbar;
	}

	get hasTitle() {
		return !!this.$slots.title || !!this.title;
	}

	get hasCancel() {
		return !!this.cancel;
	}

	get hasErrorMessage() {
		return !!this.$slots["error-message"] || !!this.errorMessage;
	}

	get hasSuccessMessage() {
		return !!this.$slots["success-message"] || !!this.successMessage;
	}

	hasRightsTo(field: FormField) {
		if (field.$can) {
			return this.$can(field.$can);
		}

		return true;
	}

	isCheckbox(field: FormField) {
		return field.type === "checkbox";
	}
	isColorSwatches(field: FormField) {
		return field.type === "color-swatches";
	}
	isDate(field: FormField) {
		return field.type === "date";
	}
	isSelect(field: FormField) {
		return field.type === "select";
	}
	isSwitch(field: FormField) {
		return field.type === "switch";
	}
	isTel(field: FormField) {
		return ["tel", "phone", "telephone"].includes(field.type);
	}
	isTextEditor(field: FormField) {
		return field.type === "text-editor";
	}

	async created() {
		this.state = FormState.Initialized;
	}

	@Watch("data", { immediate: true })
	onDataChange() {
		this.formData = clone(this.data);
	}

	protected async _cancel(e: Event) {
		return this.cancel(e);
	}

	protected async _submit(e: Event) {
		e.preventDefault();

		this.state = FormState.Submitting;

		if (this.showResponseTimeout) {
			clearTimeout(this.showResponseTimeout);
		}

		try {
			const data: any = {};

			for (const field of this.fields) {
				if (this.formData[field.key] !== undefined) {
					data[field.key] = this.formData[field.key];
				}
			}

			if (this.data.id) {
				data.id = this.data.id;
			}

			const res = await this.submit(data);

			if (!res) throw new Error();

			this.showResponseType = "success";
		} catch (err) {
			this.state = FormState.Error;

			this.showResponseType = "error";
		}

		this.showResponse = true;
		this.formData = clone(this.data);

		this.showResponseTimeout = window.setTimeout(() => {
			this.showResponse = false;
		}, 5000);
	}
}
