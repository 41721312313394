import { EntityInterface, EntityTypeEnum } from "@scrinz/dtos";

export function getEntityTypeName(entity: EntityInterface): string {
	switch (entity.type) {
		case EntityTypeEnum.Display:
			return "Display";
		case EntityTypeEnum.Group:
			return "Group";
		case EntityTypeEnum.Organization:
			return "Organization";
		case EntityTypeEnum.System:
			return "System";
		default:
			throw new TypeError("invalid entity type");
	}
}
