














































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment-timezone";

import { TransitDepartureObject } from "@scrinz/dtos";

import { SdSlideTimer } from "../SdSlideTimer";
import { ONE_MINUTE, RUTER_COLORS, TRANSIT_TIMES_THEME } from "./constants";
import {
	SdTransitTimesServiceInterface,
	SdTransitTimesData,
	SdTransitTimesStopInterface,
} from "./interfaces";
import { isSdComponentServiceInterface } from "../utils";

@Component({
	components: { SdSlideTimer },
	i18n: {
		messages: {
			en: {
				departureTimes: "Departure times",
				fromNow: "now | 1 min | {count} min",
			},
			no: {
				departureTimes: "Avgangstider",
				fromNow: "nå | 1 min | {count} min",
			},
		},
	},
})
export default class SdTransitTimes<
	Data extends
		| SdTransitTimesData
		| SdTransitTimesServiceInterface = SdTransitTimesData,
> extends Vue {
	stops: SdTransitTimesData | null = null;
	started = false;
	currentStop = 0;

	/**
	 * Property defining the stops and departures to be displayed.
	 *
	 * Expects value to be either `null`, indicating that it's loading; `false`,
	 * indicating the loading has failed and to display an error message; or an
	 * array of `SdTransitTimesStopInterface`.
	 */
	@Prop({ default: null, type: [Object] })
	transitTimes!: Data;

	get numStops() {
		return this.stops instanceof Array ? this.stops.length : 0;
	}

	// @Watch("transitTimes", { immediate: true })
	// _onTransitTimesChange(data: Data, oldData: Data) {
	// 	if (isSdComponentServiceInterface(oldData))
	// 		oldData.removeListener(this.updateData);
	// 	if (isSdComponentServiceInterface(data)) data.addListener(this.updateData);
	// 	else if (data) {
	// 		this.stops = data as SdTransitTimesData;
	// 	}
	// }

	created() {
		if (!isSdComponentServiceInterface(this.transitTimes)) return;
		this.transitTimes.addListener(this.updateData);
	}

	beforeDestroy() {
		if (!isSdComponentServiceInterface(this.transitTimes)) return;
		this.transitTimes.removeListener(this.updateData);
	}

	updateData(data?: SdTransitTimesData) {
		if (!data) return;
		this.stops = data;
		this.started = true;
	}

	onSlideChanged(slide: number) {
		this.currentStop = slide;
	}

	isCurrent(index: number) {
		return index === this.currentStop;
	}

	isNext(index: number) {
		return (
			(index === 0 && this.numStops === this.currentStop + 1) ||
			this.currentStop === index - 1
		);
	}

	isPrevious(index: number) {
		return (
			(this.numStops === index + 1 && this.currentStop === 0) ||
			this.currentStop === index + 1
		);
	}

	getStopClassFor(index: number) {
		return {
			"sd-transit-times__stops__stop--current": this.isCurrent(index),
			"sd-transit-times__stops__stop--next": this.isNext(index),
			"sd-transit-times__stops__stop--previous": this.isPrevious(index),
		};
	}

	getDeparturesForStop(stop: SdTransitTimesStopInterface) {
		return stop.departures.map((departure) => ({
			...departure,
			fromNow: this.departureTimeToMinutes(departure.departureTime),
		}));
	}

	getIconStylingForJourney(journey: TransitDepartureObject) {
		/**
		 * Code is a little hacky and/or messy but the intention is to make the
		 * separate functions silently fail when any properties are undefined, so as
		 * to only style parts that are available while leaving anything else
		 * untouched
		 */
		const bgColor = this.getTransportModeColor(
			journey.transportMode,
			journey.lineCode,
		);
		const bgSize = this.getLineCodeIconSize(journey.lineCode);
		const bgWidth = bgSize && bgSize.width;
		const bgRadius = bgSize && bgSize.radius;

		return {
			"background-color": bgColor || "transparent", // FIXME: Proper default
			width: `${bgWidth || 30}px`, // FIXME: Proper default
			"border-radius": `${bgRadius || 50}%`, // FIXME: Proper default
		};
	}

	getTransportModeColor(
		mode: string | undefined,
		lineCode: string | undefined,
	) {
		if (!mode || !lineCode) return;
		if (mode === "metro") return RUTER_COLORS.metro;
		if (mode === "localBus") return RUTER_COLORS.bus.city;
		if (mode === "bus")
			return lineCode[0] === "F" && lineCode[1] === "B"
				? RUTER_COLORS.bus.airport
				: parseInt(lineCode) >= 100
				? RUTER_COLORS.bus.district
				: undefined;
		if (mode === "water") return RUTER_COLORS.water;
		if (mode === "rail") return RUTER_COLORS.rail;
		if (mode === "localTram") return RUTER_COLORS.tram;
		return;
	}

	getLineCodeIconSize(lineCode: string) {
		if (!lineCode) return;

		const sizes = TRANSIT_TIMES_THEME.iconSizes;

		if (lineCode.length <= 2) return sizes.round;
		else if (lineCode.length === 3) return sizes.small;
		else if (lineCode.length === 4) return sizes.medium;
		else if (lineCode.length >= 5) return sizes.large;

		return;
	}

	departureTimeToMinutes(time: Date | string | moment.Moment) {
		time = moment(time);
		const now = moment();
		const diff = time.diff(now);
		return Math.floor(diff / ONE_MINUTE);
	}
}
