import { Field, InputType } from "@nestjs/graphql";
import { IsEmail } from "class-validator";

import { BaseDto } from "../..";

@InputType()
export class RemoveMemberInput extends BaseDto {
	@Field(() => String)
	@IsEmail()
	email!: string;
}
