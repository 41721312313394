import { Field, ObjectType } from "@nestjs/graphql";

import { ContentInterface } from "./content.interface";

@ObjectType("ContentNote", {
	implements: [ContentInterface],
})
export class ContentNoteObject extends ContentInterface {
	@Field(() => String)
	text!: string;

	@Field(() => String, { nullable: true })
	icon!: string | null;

	@Field(() => String, { nullable: true })
	iconColor!: string | null;

	@Field(() => Boolean)
	sticky!: boolean;
}
