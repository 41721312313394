














import { Component, Prop, Vue } from "vue-property-decorator";

import { TransitStopObject } from "@scrinz/dtos";

@Component
export default class TransitTimesStopSelectorSelectedStopsList extends Vue {
	@Prop({ default: [] })
	stops!: TransitStopObject[];

	async removeStop(stop: TransitStopObject) {
		return this.$store.dispatch("deleteTransitStop", stop);
	}
}
