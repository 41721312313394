import { Field, InputType } from "@nestjs/graphql";

import { AddContentInput } from "./add-content.input";

@InputType()
export class AddContentNoteInput extends AddContentInput {
	@Field(() => String)
	text!: string;

	@Field(() => String, { nullable: true })
	icon!: string;

	@Field(() => String, { nullable: true })
	iconColor!: string;
}
