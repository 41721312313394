var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"client-entity-details-general"},[(_vm.$can('admin'))?_c('sz-card-form',{attrs:{"title":_vm.$t("Entity settings"),"error-message":_vm.$t("Failed to update entity settings."),"success-message":_vm.$t("The entity settings have been successfully updated."),"data":_vm.entity,"submit":_vm.submit,"submit-text":_vm.$t("Update"),"fields":[
			{ key: "name", label: _vm.$t("Name") },
			{ key: "orgnum", label: _vm.$t("Organization number") },
			{ key: "street", label: _vm.$t("Street") },
			{ key: "zip", label: _vm.$t("Zip") },
			{ key: "city", label: _vm.$t("City") } ]}}):_vm._e(),_c('sz-card-form',{attrs:{"title":_vm.$t("Display settings"),"error-message":_vm.$t("Failed to update display settings."),"success-message":_vm.$t("The display settings have been sucessfully updated."),"data":_vm.entity,"submit":_vm.submit,"submit-text":_vm.$t("Update"),"fields":[
			{ key: "displayInformation", label: _vm.$t("Display information text") },
			{
				$can: "admin",
				key: "orientation",
				type: "select",
				label: _vm.$t("Orientation"),
				inputType: "number",
				options: [
					{ value: 0, text: _vm.$t("Horizontal") },
					{ value: 1, text: _vm.$t("Vertical") } ],
			},
			{
				$can: "admin",
				key: "codeWord",
				label: _vm.$t("SMS code word"),
			},
			{
				// $can: "admin",
				key: "showCodeWord",
				type: "switch",
				label: _vm.$t("Show SMS code word and sending instructions?"),
			},
			{
				key: "noteBoardBackgroundColor",
				type: "color-swatches",
				label: _vm.$t("Background color for note board"),
				palette: [
					["#e0d6d0"], ["#f7d4d2"], ["#eeeeee"], ["#d0e5be"], ["#daedf1"] ],
			} ]}}),_c('transit-times-stop-selector',{attrs:{"entity":_vm.entity}}),_c('settings-features',{attrs:{"id":_vm.id}}),(_vm.$can('admin'))?_c('sz-content-card',[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t("Housekeeping")))]),_c('template',{slot:"content"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("Delete entity"))+" ")])],1)],2):_vm._e(),_c('entity-delete-dialog',{attrs:{"entity":_vm.entity},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }