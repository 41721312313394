







import { Component, Prop, Vue } from "vue-property-decorator";

import UserDetails from "./UserDetails/Details.vue";
import NewPassword from "./UserDetails/NewPassword.vue";

@Component({
	components: { UserDetails, NewPassword }
})
export default class AdminUserDetails extends Vue {
	@Prop()
	userId!: number;
}
