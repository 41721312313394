import AutoApproveNumbersCard from "./Card.vue";
import AutoApproveNumbersEditDialog from "./EditDialog.vue";
import AutoApproveNumbersEmptyState from "./EmptyState.vue";
import AutoApproveNumbersList from "./List.vue";

export {
	AutoApproveNumbersCard,
	AutoApproveNumbersEditDialog,
	AutoApproveNumbersEmptyState,
	AutoApproveNumbersList,
};

export default AutoApproveNumbersCard;
