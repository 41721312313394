import { Field, ObjectType } from "@nestjs/graphql";

import { ContentBannerInterface } from "./content-banner.interface";
import { ContentInterface } from "./content.interface";

@ObjectType("ContentBannerTemplate", {
	implements: [ContentInterface, ContentBannerInterface],
})
export class ContentBannerTemplateObject extends ContentBannerInterface {
	@Field()
	template!: string;

	@Field(() => String, { nullable: true })
	templateText!: string | null;
}
