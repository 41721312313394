



















import { Component, Prop, Vue } from "vue-property-decorator";
import clone from "lodash/clone";

import { TransitStopObject } from "@scrinz/dtos";
import { EntityInterface } from "@scrinz/dtos";

import AddDialog from "./AddDialog.vue";
import EmptyState from "./EmptyState.vue";
import SelectedStopsList from "./SelectedStopsList.vue";

@Component({
	components: {
		AddDialog,
		EmptyState,
		SelectedStopsList,
	},
})
export default class TransitTimesStopSelectorCard extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	showAddDialog = false;
	addStop: TransitStopObject = this.EMPTY_STOP;

	// tslint:disable-next-line
	get EMPTY_STOP(): TransitStopObject {
		return clone({
			id: (undefined as any) as number,
			entityId: this.entity.id,
			stopId: "",
			label: "",
		});
	}

	get stops() {
		return this.$store.getters.transitStopsFor(this.entity);
	}

	get isEmpty() {
		return this.stops.length === 0;
	}

	async mounted() {
		await this.fetchTransitStops();
	}

	async fetchTransitStops() {
		await this.$store.dispatch("fetchTransitStops", this.entity);
	}

	openAddStopDialog() {
		this.addStop = this.EMPTY_STOP;
		this.showAddDialog = true;
	}
}
