













import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class AutoApproveNumbersEmptyState extends Vue {
	@Prop({ required: true, type: [Function] })
	editFunction!: any;
}
