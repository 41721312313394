import { Field, InputType, Int } from "@nestjs/graphql";
import {
	IsEnum,
	IsInt,
	IsOptional,
	IsString,
	MaxLength,
	MinLength,
} from "class-validator";

import { EntityTypeEnum } from "../entity-type.enum";

@InputType()
export class CreateEntityInput {
	@Field(() => String)
	@IsString({ message: "Entity name must be a string." })
	@MinLength(3, {
		message: "Entity name has to be at least 3 characters long.",
	})
	@MaxLength(255, {
		message: "Entity name cannot be more than 255 characters long.",
	})
	name!: string;

	@Field(() => EntityTypeEnum)
	@IsEnum(EntityTypeEnum, {
		message: "Entity type is not one of accepted values of `EntityType`.",
	})
	type!: EntityTypeEnum;

	// TODO: To consider, should this be options? Only admins should be able to
	// create root entities. Maybe create an `AdminCreateEntityInterface` for those cases?
	@Field(() => Int, { nullable: true })
	@IsInt({ message: "Entity parentId must be an integer." })
	@IsOptional()
	parentId?: number;

	@Field(() => String, { nullable: true })
	@IsOptional()
	@IsString({ message: "Entity codeWord must be a string." })
	@MinLength(3, {
		message: "Entity codeWord has to be at least 3 characters long.",
	})
	@MaxLength(10, {
		message: "Entity codeWord cannot be more than 10 characters long.",
	})
	codeWord?: string;
}
