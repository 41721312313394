import { Field, InputType } from "@nestjs/graphql";
import { IsString } from "class-validator";

import { BaseDto } from "..";

@InputType()
export class RegisterDisplayInput extends BaseDto {
	@Field(() => String)
	@IsString()
	displayId!: string;

	@Field(() => String)
	@IsString()
	apiKey!: string;
}
