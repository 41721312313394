import { ActionTree } from "vuex";

import http from "@/http";
import { RootState } from "@/store";
import { StatusState } from "./types";

export const actions: ActionTree<StatusState, RootState> = {
	async init({ dispatch, getters }) {
		// Dispatch to `setOnlineState` with no value, using browser state.
		await dispatch("setOnlineState");

		// Attach event listeners to changes in online state.
		window.addEventListener("online", () => {
			void dispatch("setOnlineState", true);
		});
		window.addEventListener("offline", () => {
			void dispatch("setOnlineState", false);
		});

		// Dispatch to test connectivity with server.
		await dispatch("testConnectivity");

		return {
			connectivity: {
				isOnline: getters.isOnline,
				hasConnectivity: getters.hasConnectivity,
			},
		};
	},

	async getStatus({ commit }) {
		const res = await http.get("status");

		if (!res || !res.data) return false;

		const data = res.data;

		commit("SET_API_INSTALLED_STATE", data.uninstalled ? false : true);

		return data;
	},

	async installApi({ commit }, payload) {
		const res = await http.post("admin/install", payload);

		if (res) {
			commit("SET_API_INSTALLED_STATE", true);
			window.location.href = "/";
		}
	},

	async testConnectivity(
		{ commit, dispatch },
		retry: boolean | number = false,
	): Promise<boolean> {
		let connectivity = false;
		let status: any;

		// Flag we are testing connectivity.
		commit("SET_IS_TESTING_CONNECTIVITY", true);

		// Try to get status from server.
		try {
			status = await dispatch("getStatus");
		} catch (e) {
			/* Do nothing, as fallthrough is no connectivity. */
		}

		// If got a result, has data and online parameter is `true`, set
		// connectivity to `true`.
		if (status && status.online === true) {
			connectivity = true;
		}

		// If no connectivity and is flagged to retry.
		if (!connectivity && retry) {
			// Ensure retry is a number, so we can limit number of requests.
			if (retry && typeof retry !== "number") {
				retry = 10; // tslint:disable-line:no-magic-numbers
			}

			// Return a new Promise to test connectivity in 3000ms.
			return new Promise<boolean>((resolve) =>
				setTimeout(
					() => {
						resolve(dispatch("testConnectivity", (retry as number) - 1));
					},
					3000, // tslint:disable-line:no-magic-numbers
				),
			);
		}

		// Commit is done testing, new online state and server connectivity.
		commit("SET_IS_TESTING_CONNECTIVITY", false);
		// await dispatch("setOnlineState", connectivity);
		await dispatch("setServerConnectivity", connectivity);

		return connectivity;
	},

	async setOnlineState(
		{ commit, dispatch, getters },
		onlineState: boolean = navigator.onLine,
	) {
		commit("SET_ONLINE_STATE", onlineState);
		await dispatch("onConnectivityChange", getters.hasConnectivity);
	},

	async setServerConnectivity(
		{ commit, dispatch, getters },
		connectivity: boolean,
	) {
		commit("SET_SERVER_CONNECTIVITY", connectivity);
		await dispatch("onConnectivityChange", getters.hasConnectivity);
	},

	onConnectivityChange(_, _connectivity: boolean) {
		// To be consumed by other modules.
	},
};
