import Vue, { PluginObject } from "vue";

export interface SdPluginOptions {
	entur?: { clientName: string };
}

export interface SdPluginObject extends PluginObject<SdPluginOptions> {
	options: SdPluginOptions;
}

import * as Sd from "./Sd";

function nameToTag(name: string) {
	return name
		.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)
		.replace(/^-/, "");
}

export const SdPlugin: SdPluginObject = {
	options: {},
	install: (V) => {
		for (const name of Object.keys(Sd)) {
			const tag = nameToTag(name);
			const component: (t: string, c: Vue) => void = (Sd as any)[name];

			if (
				typeof component === "function" &&
				component.prototype instanceof Vue
			) {
				V.component(tag, component);
			}
		}
	},
};
