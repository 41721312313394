import { IsEnum, IsInt } from "class-validator";

import { Field, Int, ObjectType } from "@nestjs/graphql";

import { BaseDto, UserRoleEnum } from "..";

import { UserRoleInterface } from "./interfaces";

@ObjectType("UserRole")
export class UserRoleObject extends BaseDto<UserRoleInterface> {
	@Field(() => Int)
	@IsInt()
	entityId!: number;

	@Field(() => Int)
	@IsInt()
	userId!: number;

	@Field(() => UserRoleEnum)
	@IsEnum(UserRoleEnum)
	role!: UserRoleEnum;

	// getPermissions(): UserPermissionDto[] {
	// 	if (!this.role) return [];

	// 	return UserRolePermissions[this.role].map(
	// 		(perm) =>
	// 			new UserPermissionDto({
	// 				entityId: this.entityId,
	// 				userId: this.userId,
	// 				permission: perm,
	// 			}),
	// 	);
	// }
}
