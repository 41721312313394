









































import Vue from "vue";
import { Component } from "vue-property-decorator";
import getenv from "getenv.ts";

import { API_URL, DISPLAY_URL } from "@/constants";

@Component
export default class DevelopmentToolbar extends Vue {
	get apiUrl() {
		return API_URL;
	}

	get displayUrl() {
		return DISPLAY_URL;
	}

	get isDevelopmentEnvironment() {
		return getenv.string("NODE_ENV") === "development";
	}
}
