













import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class ContextSwitcher extends Vue {
	@Getter organizations!: any[];

	get entityContextId() {
		return this.$store.getters.entityContextId;
	}

	set entityContextId(id) {
		void this.$store.dispatch("setEntityContext", id);
	}
}
