// tslint:disable:no-require-imports
export const TEMPLATES = [
	{
		id: "bunny",
		category: "seasonal",
		image: require("./bunny.png"),
		fontColor: "dark",
	},
	{ id: "17-may", category: "seasonal", image: require("./17-may.png") },
	{ id: "autum", category: "seasonal", image: require("./autum.png") },
	{ id: "barbequing", category: "rules", image: require("./barbequing.png") },
	{ id: "cake", category: "general", image: require("./cake.png") },
	{ id: "cigarette", category: "rules", image: require("./cigarette.png") },
	{ id: "clear-exits", category: "rules", image: require("./clear-exits.png") },
	{ id: "close-door", category: "rules", image: require("./close-door.png") },
	{
		id: "dog-backyard",
		category: "rules",
		image: require("./dog-backyard.png"),
	},
	{ id: "drainage", category: "general", image: require("./drainage.png") },
	{ id: "dugnad", category: "rules", image: require("./dugnad.png") },
	{ id: "electrical", category: "hse", image: require("./electrical.png") },
	{ id: "fire", category: "hse", image: require("./fire.png") },
	{ id: "garage", category: "rules", image: require("./garage.png") },
	{ id: "grass", category: "rules", image: require("./grass.png") },
	{ id: "halloween", category: "seasonal", image: require("./halloween.png") },
	{ id: "handyman", category: "general", image: require("./handyman.png") },
	{ id: "meeting", category: "general", image: require("./meeting.png") },
	{ id: "quiet", category: "rules", image: require("./quiet.png") },
	{
		id: "roof-terrace",
		category: "rules",
		image: require("./roof-terrace.png"),
	},
	{ id: "summer", category: "seasonal", image: require("./summer.png") },
	{ id: "trash", category: "general", image: require("./trash.png") },
	{ id: "winter", category: "seasonal", image: require("./winter.png") },
];
// tslint:enable:no-require-imports
