export const SD_MATERIAL_ICON_LIGATURE_MAP: { [name: string]: string } = {
	priority_high: "e645",
	comment: "e0b9",
	thumb_up_alt: "e8dc",
	thumb_down_alt: "e8db",
	local_parking: "e54f",
	warning: "e002",
	sentiment_satisfied_alt: "e813",
	group: "e7ef",
	build: "e869",
	error_outline: "e001",
	gavel: "e90e",
	alarm: "e855",
	settings: "e8b8",
};
