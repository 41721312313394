import { Type } from "@nestjs/common";
import { InputType, PartialType } from "@nestjs/graphql";
import { IsOptional } from "class-validator";

import { EntityTypeEnum } from "../entity-type.enum";
import { CreateEntityInput } from "./create-entity.input";

const Part =
	typeof PartialType === "function"
		? PartialType
		: (type: any) => {
				class PP extends type {}

				return PP as Type<Partial<CreateEntityInput>>;
		  };

@InputType()
export class UpdateEntityInput extends Part(CreateEntityInput) {
	@IsOptional()
	name!: string;

	@IsOptional()
	type!: EntityTypeEnum;

	@IsOptional()
	parentId!: number;
}
