












import Vue from "vue";
import Component from "vue-class-component";

import LayoutCentered from "@/layouts/Centered.vue";

@Component({
	title: () => "404: Page Not Found",
	components: { LayoutCentered },
})
export default class Error404 extends Vue {}
