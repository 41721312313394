import { UserRoleEnum } from "@scrinz/dtos";

export function getRoleName(role: UserRoleEnum): string {
	switch (role) {
		case UserRoleEnum.Administrator:
			return "Administrator";
		case UserRoleEnum.Moderator:
			return "Moderator";
		case UserRoleEnum.Owner:
			return "Owner";
		default:
			return "Invalid role";
	}
}
