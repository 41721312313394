import { Field, Int, InterfaceType } from "@nestjs/graphql";

import { ContentTypeEnum } from "./content-type.enum";
import { ContentInterface } from "./content.interface";

@InterfaceType({
	implements: [ContentInterface],
	isAbstract: true,
	description:
		"Interface describing common properties for all banner content sub-types.",
	resolveType(content: ContentBannerInterface) {
		switch (content.type) {
			case ContentTypeEnum.BannerAsset:
				return "ContentBannerAsset";
			case ContentTypeEnum.BannerTemplate:
				return "ContentBannerTemplate";
			default:
				throw new TypeError("failed to resolve entity type");
		}
	},
})
export class ContentBannerInterface extends ContentInterface {
	@Field(() => Int, { nullable: true })
	duration!: number | null;
}
