import { Field, Int, ObjectType } from "@nestjs/graphql";
import { AssetTypeEnum } from "./asset-type.enum";

@ObjectType("Asset")
export class AssetObject {
	@Field(() => Int)
	id!: number;

	@Field(() => String)
	hashid!: string;

	@Field()
	name!: string;

	@Field()
	mime!: string;

	@Field(() => Int)
	bytes!: number;

	@Field(() => AssetTypeEnum)
	type!: AssetTypeEnum;

	@Field(() => Int)
	page!: number;

	@Field(() => Int)
	offsetX!: number;

	@Field(() => Int)
	offsetY!: number;

	@Field()
	scale!: number;

	@Field()
	hasCropped!: boolean;
}
