





























































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
// import PDFJS from "pdfjs-dist";

import { AssetDto, AssetTypeEnum, EntityInterface } from "@scrinz/dtos";
import { API_URL } from "@/constants";
import store from "@/store";

import Dropzone from "vue2-dropzone";
import AssetUploaderCropper from "./Cropper.vue";
import AssetUploaderPdfCanvas from "./PdfCanvas.vue";

@Component({
	components: { AssetUploaderCropper, AssetUploaderPdfCanvas, Dropzone },
})
export default class AssetUploader extends Vue {
	id = `asset-uploader-${Math.random() * 1000}`; // tslint:disable-line

	@Prop({ required: true, type: [Object] })
	entity!: EntityInterface;

	@Prop({ default: 480 })
	width!: number;

	@Prop({ default: 895 })
	height!: number;

	asset: AssetDto | null = null;

	@Prop()
	value!: string;

	pdf: any | /* PDFJS.Document */ null = null;

	options = {
		headers: {
			Authorization: `Bearer ${store.getters.token}`,
		},
		autoQueue: false,
		maxFiles: 1,
		url: `${API_URL}/assets/upload`,
	};

	// tslint:disable:no-magic-numbers
	scaleOptions = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
	// tslint:enable:no-magic-numbers

	errorMessage = "";

	get originalImageUrl() {
		return (
			(this.asset &&
				`${API_URL}/assets/${
					this.asset.id
				}/original?cache=${new Date().getTime()}`) ||
			null
		);
	}

	get pdfPages() {
		return (
			(this.pdf &&
				Array.from({ length: this.pdf.numPages }, (_, k) => k + 1)) ||
			[]
		);
	}

	isImage() {
		return this.asset && this.asset.type === AssetTypeEnum.Image;
	}

	isPdf() {
		return this.asset && this.asset.type === AssetTypeEnum.Pdf;
	}

	@Watch("value", { deep: true, immediate: true })
	async fetchAsset() {
		this.asset = this.value
			? await this.$store.dispatch("fetchAsset", this.value)
			: null;
	}

	async onFileAdded(file: any) {
		const res = await this.$store.dispatch("uploadAsset", {
			file,
			entity: this.entity,
		});

		(this.$refs[this.id] as any).removeFile(file);

		this.$emit("input", res.id);
	}

	async save() {
		if (!this.asset) return;

		const cropper = this.$refs["cropper"] as any;
		const cropped = (await cropper.getCropped()) as Blob | null;

		if (cropped) {
			await this.$store.dispatch("saveCroppedAsset", {
				cropped,
				id: this.asset.id,
			});
		}

		const asset = await this.$store.dispatch("updateAsset", this.asset);

		this.$emit("input", asset.id);

		return asset;
	}

	async clearAsset() {
		this.pdf = null;
		this.$emit("input", null);
	}
}
