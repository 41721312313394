import { Field, ObjectType } from "@nestjs/graphql";
import { IsString } from "class-validator";

import { BaseDto } from "..";

@ObjectType("AuthenticationToken")
export class AuthenticationTokenObject extends BaseDto {
	@Field(() => String)
	@IsString()
	token!: string;
}
