import { Field, InputType, Int } from "@nestjs/graphql";

import { AddContentInput } from "./add-content.input";

@InputType()
export class AddContentBannerInput extends AddContentInput {
	@Field(() => Int, { nullable: true })
	duration!: number | null;

	@Field(() => Date, { nullable: false })
	end!: Date;
}
