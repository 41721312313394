var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sz-card-form',{staticClass:"client-settings-language",attrs:{"title":_vm.$t("routes.settings.language.title"),"error-message":_vm.$t("routes.settings.language.errorMessage"),"success-message":_vm.$t("routes.settings.language.successMessage"),"data":_vm.user,"submit":_vm.submit,"fields":[
		{
			key: 'language',
			label: _vm.$t('routes.settings.language.fields.language'),
			type: 'select',
			options: [
				{ value: 'en', text: 'English' },
				{ value: 'no', text: 'Norwegian' } ],
		} ]}})}
var staticRenderFns = []

export { render, staticRenderFns }