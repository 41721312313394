


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { FormField } from "./CardForm.vue";

@Component
export default class SzDialogCardForm extends Vue {
	@Prop({ default: false })
	value!: boolean;

	@Prop({ default: () => ({}) })
	data!: any;

	@Prop()
	title!: string;

	@Prop()
	errorMessage!: string;

	@Prop()
	successMessage!: string;

	@Prop({ required: true, type: [Function] })
	submit!: any;

	@Prop({ type: [Function] })
	cancel!: any;

	@Prop({ required: true, type: [Array] })
	fields!: FormField[];

	@Prop({ type: [String, Number] })
	maxWidth!: string | number;

	get isOpen() {
		return !!this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	protected async _cancel(e: Event) {
		if (this.cancel) {
			return this.cancel(e);
		}

		this.isOpen = false;
	}
}
