import { Field, Int, InterfaceType } from "@nestjs/graphql";
import { BaseDto } from "../base.dto";

import { EntityTypeEnum } from "./entity-type.enum";
import { EntityUserObject } from "./entity-user.object";

@InterfaceType({
	isAbstract: true,
	resolveType(entity: EntityInterface) {
		switch (entity.type) {
			case EntityTypeEnum.Display:
				return "DisplayEntity";
			case EntityTypeEnum.Group:
				return "GroupEntity";
			case EntityTypeEnum.Organization:
				return "OrganizationEntity";
			case EntityTypeEnum.System:
				return "SystemEntity";
			default:
				throw new TypeError("failed to resolve entity type");
		}
	},
})
export class EntityInterface extends BaseDto {
	@Field(() => Int)
	id!: number;

	@Field(() => Int, { nullable: true })
	parentId!: number | null;

	@Field(() => EntityInterface, { nullable: true })
	parent!: EntityInterface | null;

	@Field(() => EntityTypeEnum)
	type!: EntityTypeEnum;

	@Field(() => [EntityInterface])
	children!: EntityInterface[];

	@Field(() => [EntityUserObject])
	members!: EntityUserObject[];

	@Field(() => String)
	name!: string;

	@Field(() => String, { nullable: true })
	apiKey!: string | null;

	@Field(() => String, { nullable: true })
	codeWord!: string | null;

	@Field(() => String, { nullable: true })
	street!: string | null;

	@Field(() => String, { nullable: true })
	zip!: string | null;

	@Field(() => String, { nullable: true })
	city!: string | null;

	@Field(() => String, { nullable: true })
	displayInformation!: string | null;

	@Field(() => String, { nullable: true })
	noteBoardBackgroundColor!: string | null;

	@Field(() => String, { nullable: true })
	bannerFallbackText!: string | null;
}
