import { Field, ObjectType } from "@nestjs/graphql";

@ObjectType("TransitDeparture")
export class TransitDepartureObject {
	@Field(() => Date)
	departureTime!: Date;

	@Field(() => String)
	frontText!: string;

	@Field(() => String)
	lineCode!: string;

	@Field(() => String, { nullable: true })
	transportMode?: string;
}
