




import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SdServiceModulesDivider extends Vue {
	@Prop({ default: false })
	vertical!: boolean;
}
