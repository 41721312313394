import { MutationTree } from "vuex";

import { AssetsState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<AssetsState> = {
	RESET_STATE(state) {
		state.assets = [];
	},

	UPDATE_ASSET(state, asset) {
		const current = state.assets.find((t) => t.id === asset.id);

		// asset = prepTemplate(asset);

		if (current) {
			Object.assign(current, asset);
		} else {
			state.assets.push(asset);
		}
	},
};
