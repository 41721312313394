var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"note-board-note",class:{
		active: _vm.isActive,
		expired: _vm.isExpired,
		inherited: _vm.isInherited,
		scheduled: _vm.isScheduled,
		'newly-created': _vm.note.newlyCreated,
		'newly-updated': _vm.note.newlyUpdated,
	}},[(_vm.index !== 0)?_c('v-divider'):_vm._e(),_c('div',{staticClass:"header"},[_c('div',{staticClass:"meta"},[(_vm.isActive)?_c('span',{staticClass:"state success--text"},[_vm._v(" "+_vm._s(_vm.$t("common.active"))+" ")]):(_vm.isScheduled)?_c('span',{staticClass:"state info--text"},[_vm._v(" "+_vm._s(_vm.$t("common.scheduled"))+" ")]):(_vm.isExpired)?_c('span',{staticClass:"state error--text"},[_vm._v(" "+_vm._s(_vm.$t("common.expired"))+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('span',_vm._g({staticClass:"date"},on),[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.note.date,"D. MMMM"))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.note.date,"D. MMMM YYYY"))+" ")]),(_vm.showEndDate)?_c('span',[_vm._v(" - ")]):_vm._e(),(_vm.showEndDate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.displayEndDate,"D. MMMM"))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.displayEndDate,"D. MMMM YYYY"))+" ")]):_vm._e(),_c('span',{staticClass:"statuses"},[(_vm.note.sticky)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('span',_vm._g({staticClass:"pinned"},on),[_c('v-icon',[_vm._v("lock_outline")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Pinned to top"))+" ")]):_vm._e(),(_vm.note.approved)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('span',_vm._g({staticClass:"approved center-text"},on),[_c('v-icon',{attrs:{"color":'success'}},[_vm._v("done")]),_c('span',[_vm._v(_vm._s(_vm.$t("Published")))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Approved"))+" ")]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('span',_vm._g({staticClass:"unapproved center-text"},on),[_c('v-icon',{attrs:{"color":'error'}},[_vm._v("block")]),_c('span',[_vm._v(_vm._s(_vm.$t("Not published")))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Not approved"))+" ")])],1)],1),(!_vm.isInherited)?_c('div',{staticClass:"actions"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("menu")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(_vm.note)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.note-board.editNote")))])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("edit")])],1)],1),(!_vm.note.approved)?_c('v-list-item',{on:{"click":function($event){return _vm.toggleApproval(_vm.note)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.note-board.approveNote")))])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("done")])],1)],1):_c('v-list-item',{on:{"click":function($event){return _vm.toggleApproval(_vm.note)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.note-board.rejectNote")))])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("block")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.deleteNote(_vm.note)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.note-board.deleteNote")))])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("delete_forever")])],1)],1)],1)],1)],1):_c('span',{staticClass:"inherited-info"},[_vm._v(" "+_vm._s(_vm.$t("Inherited from"))+" "),_c('router-link',{attrs:{"to":("/entities/" + (_vm.note.entityId) + "/notes")}},[_vm._v(_vm._s(_vm.$store.getters.getEntity(_vm.note.entityId).name))])],1)]),_c('div',{staticClass:"content",style:({
			background: ((this.entity.noteBoardBackgroundColor) + "aa"),
		})},[_c('sd-note',{ref:"notes",staticClass:"note-board-notes__note",attrs:{"date":_vm.note.date,"icon":_vm.note.icon,"icon-background-color":_vm.note.iconColor,"html-body":_vm.note.text}})],1),(_vm.index !== _vm.listLength - 1)?_c('v-divider'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }