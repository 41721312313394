import {
	EntityInterface,
	EntityTypeEnum,
	GroupEntityObject,
} from "@scrinz/dtos";

export function isGroup(entity: unknown): entity is GroupEntityObject {
	return (
		entity instanceof EntityInterface && entity.type === EntityTypeEnum.Group
	);
}
