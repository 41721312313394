import { registerEnumType } from "@nestjs/graphql";

export enum EntityTypeEnum {
	System = "system",
	Group = "group",
	Organization = "organization",
	Display = "display",
}

registerEnumType(EntityTypeEnum, {
	name: "EntityType",
});
