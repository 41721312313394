












import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import AppNavigationDrawer from "@/components/AppNavigationDrawer.vue";
import AppToolbar from "@/components/AppToolbar.vue";
import DevelopmentToolbar from "@/components/DevelopmentToolbar.vue";

@Component({
	components: {
		AppNavigationDrawer,
		AppToolbar,
		DevelopmentToolbar,
	},
})
export default class App extends Vue {
	@Getter hasSession!: boolean;
}
