import { Field, ObjectType } from "@nestjs/graphql";
import { BaseDto } from "../base.dto";
import { TransitDepartureObject } from "./transit-departure.object";

@ObjectType("TransitStopDepartures")
export class TransitStopDeparturesObject extends BaseDto {
	@Field(() => String)
	stopId!: string;

	@Field(() => [TransitDepartureObject])
	departures!: TransitDepartureObject[];
}
