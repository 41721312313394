















































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { EditFunction } from "./interfaces";
import { SdNote } from "@scrinz/components";

@Component({
	components: {
		SdNote,
	},
})
export default class NoteBoardNote extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true })
	note!: any;

	@Prop({ required: true, type: [Function] })
	edit!: EditFunction;

	@Prop({ default: 0 })
	index!: number;

	@Prop({ default: 0 })
	listLength!: number;

	now = new Date();

	get start(): Date {
		return this.note.date;
	}

	get end(): Date {
		return this.note.end;
	}

	get isActive(): boolean {
		return (
			this.note.date < this.now && (this.note.end > this.now || !this.note.end)
		);
	}

	get isScheduled(): boolean {
		return this.note.date > this.now;
	}

	get isExpired(): boolean {
		return this.note.end && this.note.end < this.now;
	}

	get isInherited() {
		return this.note.entityId !== this.entity.id;
	}

	get showEndDate() {
		if (!this.end) return false;

		const test = new Date(this.end);

		test.setDate(this.end.getDate() - 1);

		if (
			this.start.getFullYear() === test.getFullYear() &&
			this.start.getMonth() === test.getMonth() &&
			this.start.getDate() === test.getDate() &&
			test.getHours() === 0 &&
			test.getMinutes() === 0
		) {
			return false;
		}

		return this.end;
	}

	get displayEndDate() {
		const end = this.end;

		if (end) {
			if (end.getHours() === 0 && end.getMinutes() === 0) {
				end.setDate(end.getDate() - 1);
			}
		}

		return end;
	}

	async toggleApproval(note: any) {
		note.approved = !note.approved;
		await this.$store.dispatch("updateNote", note);
	}

	async deleteNote(note: any) {
		const confirmed = await this.$confirm(this.$t(`confirmDelete.text`), {
			color: "error",
			title: this.$t(`confirmDelete.title`),
		});

		if (!confirmed) return;

		await this.$store.dispatch("deleteNote", note);
	}
}
