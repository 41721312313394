






























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { AssetObject, EntityInterface } from "@scrinz/dtos";
import { API_URL } from "@/constants";

import SlotButton from "./Slot/Button.vue";
import SlotContent from "./Slot/Content.vue";
import SlotPopup from "./Slot/Popup.vue";

import { getTemplateById } from "@/assets/templates";

@Component({
	components: { SlotButton, SlotContent, SlotPopup },
})
export default class BannerEditorSlot extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true })
	banner!: any;

	@Prop({ default: false })
	isLocked!: boolean;

	@Prop({ default: false })
	startsBeforeView!: boolean;

	imageUrl = "";
	isHover = false;

	get isEditable() {
		return !this.isLocked && !this.isInherited;
	}

	get isInherited() {
		return this.entity.id !== this.banner.entityId;
	}

	get classes() {
		return {
			"banner-editor__slot": true,
			"banner-editor__slot--editable": this.isEditable,
			"banner-editor__slot--inherited": this.isInherited,
			"banner-editor__slot--locked": this.isLocked,
			"banner-editor__slot--starts-before-view": this.startsBeforeView,
		};
	}

	get asset(): AssetObject {
		return this.$store.getters.getAsset(this.banner.asset);
	}

	get inheritedFrom() {
		return this.$store.getters.getEntity(this.banner.entityId);
	}

	onMouseOver(_e: MouseEvent) {
		// console.log(e);
		this.isHover = true;
	}

	onMouseOut(_e: MouseEvent) {
		this.isHover = false;
	}

	// @Watch("asset", { deep: true, immediate: true })
	@Watch("banner", { deep: true, immediate: true })
	onBannerChange() {
		console.log(this.banner);
		if (!this.banner) return;

		if (this.banner.type === "banner-asset") {
			if (!this.banner.asset) return;

			const version = this.banner.asset.hasCropped ? "cropped" : "original";
			const url = API_URL;
			const path = `/assets/${this.banner.asset}/${version}`;
			const cache = `?cache=${new Date().getTime()}`;

			this.imageUrl = `${url}${path}${cache}`;
		} else if (this.banner.type === "banner-template") {
			const template = getTemplateById(this.banner.template);

			if (template) {
				this.imageUrl = template.image;
			}
		}
	}
}
