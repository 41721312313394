import { EntityTypeEnum } from "@scrinz/dtos";

export function getIconForEntityType(type: EntityTypeEnum): string | null {
	switch (type) {
		case EntityTypeEnum.Group:
			return "filter_none";
		case EntityTypeEnum.Organization:
			return "account_balance";
		case EntityTypeEnum.Display:
			return "tv";
		default:
			return null;
	}
}
