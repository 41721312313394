







import { Component, Prop, Vue } from "vue-property-decorator";

import { DisplayManifestObject } from "@scrinz/dtos";

import { isSdComponentServiceInterface } from "../utils";
import { SdNoteBoardData, SdNoteBoardServiceInterface } from "./interfaces";
import SdNoteBoardInfo from "./SdNoteBoardInfo.vue";
import SdNoteBoardNotes from "./SdNoteBoardNotes.vue";

@Component({
	components: { SdNoteBoardInfo, SdNoteBoardNotes },
})
export default class NoteBoard extends Vue {
	internalNotes: any[] = [];
	_tickTimeout!: any;

	@Prop({ required: true, type: [Object] })
	manifest!: DisplayManifestObject;

	@Prop({ default: () => [], type: [Array, Object] })
	notes!: SdNoteBoardData | SdNoteBoardServiceInterface;

	@Prop()
	logo!: string;

	@Prop({ default: () => false, type: [Boolean] })
	noInfo!: boolean;

	get filtered() {
		if (!this.internalNotes) return [];

		return this.internalNotes.filter((n) => n._show === true);
	}

	get noteBoardStyles() {
		return {
			background:
				this.manifest.display.noteBoardBackgroundColor ||
				this.manifest.organization?.noteBoardBackgroundColor,
		};
	}

	get sorted() {
		return this.filtered.sort((a, b) => {
			const av = a.start.valueOf();
			const bv = b.start.valueOf();

			if (av > bv) return -1;
			if (av < bv) return 1;

			if (av === bv) {
				if (a.id > b.id) return -1;
				if (a.id < b.id) return 1;
			}

			return 0;
		});
	}

	created() {
		if (!isSdComponentServiceInterface(this.notes)) return;
		this.notes.addListener(this.updateData);
	}

	beforeDestroy() {
		if (!isSdComponentServiceInterface(this.notes)) return;
		this.notes.removeListener(this.updateData);
	}

	updateData(notes: SdNoteBoardData) {
		const now = new Date().valueOf();

		this.internalNotes = notes.map((note) => {
			note.start = new Date(note.start);
			note._show = true;
			if (note.start.valueOf() > now) note._show = false;
			if (note.end) note.end = new Date(note.end);
			return note;
		});

		this.tickUpdateNotes();
	}

	tickUpdateNotes() {
		clearTimeout(this._tickTimeout);

		const now = new Date().valueOf();

		this.internalNotes.forEach((note) => {
			if (note._show === false && note.start.valueOf() < now) {
				note._show = true;
			}

			if (note._show === true && note.end && note.end.valueOf() < now) {
				note._show = false;
			}
		});

		this._tickTimeout = setTimeout(this.tickUpdateNotes.bind(this), 1000);
	}
}
