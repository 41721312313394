


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

export interface TemplateCategorySelectorCategory {
	key: null | string;
	label: any;
	hasItems: boolean;
}

@Component
export default class TemplateCategorySelector extends Vue {
	@Prop()
	templates!: any[];

	categories: TemplateCategorySelectorCategory[] = [];
	selected: null | string = null;

	get filteredCategories() {
		return this.categories.filter((c) => c.hasItems);
	}

	classForCategory(category: TemplateCategorySelectorCategory) {
		return {
			"template-category-selector__category": true,
			"template-category-selector__category--selected":
				category.key === this.selected,
		};
	}

	@Watch("templates", { immediate: true })
	onTemplatesChange() {
		const categories = [
			{
				key: null,
				label: this.$t(
					"components.banner-editor.category-selector.categories.all",
				),
				hasItems: true,
			},
			{
				key: "general",
				label: this.$t(
					"components.banner-editor.category-selector.categories.general",
				),
				hasItems: false,
			},
			{
				key: "rules",
				label: this.$t(
					"components.banner-editor.category-selector.categories.rules",
				),
				hasItems: false,
			},
			{
				key: "hse",
				label: this.$t(
					"components.banner-editor.category-selector.categories.hse",
				),
				hasItems: false,
			},
			{
				key: "seasonal",
				label: this.$t(
					"components.banner-editor.category-selector.categories.seasonal",
				),
				hasItems: false,
			},
		];

		for (const template of this.templates) {
			let category = categories.find((c) => c.key === template.category);

			if (!category) {
				continue;
			}

			category.hasItems = true;
		}

		this.categories = categories;
	}

	onCategoryClick(category: TemplateCategorySelectorCategory) {
		this.selected = category.key;

		if (
			this.$listeners.change &&
			typeof this.$listeners.change === "function"
		) {
			this.$listeners.change(category.key);
		}
	}
}
