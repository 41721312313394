import BaseModel from "./BaseModel";

import { Color } from "./Color";

export class Note extends BaseModel {
	id!: number;
	title!: string;
	text!: string;
	color!: Color;
	author!: string;
	date!: Date;
	end!: Date;
	icon!: string;
	iconColor!: string;
	sticky!: boolean;
}
