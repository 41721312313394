import { UserRoleEnum } from "../user/user-role.enum";

export enum UserPermission {
	// Owner
	DeleteEntity = "delete_entity",

	// Administrator
	CreateEntity = "create_entity",
	EditEntity = "edit_entity",

	// Moderator
	ModerateContent = "moderate_content",
	Read = "read",
}

export interface UserRoleInterface {
	id?: number;
	entityId: number;
	userId: number;
	role: UserRoleEnum;
}

export interface UserPermissionInterface {
	entityId: number;
	userId: number;
	permission: UserPermission;
}

export interface UserRolePermissionsInterface {
	[role: string]: UserPermission[];
}

export const UserRolePermissions: UserRolePermissionsInterface = {};

UserRolePermissions[UserRoleEnum.Moderator] = [
	UserPermission.ModerateContent,
	UserPermission.Read,
];

UserRolePermissions[UserRoleEnum.Administrator] = [
	...UserRolePermissions[UserRoleEnum.Moderator],
	UserPermission.CreateEntity,
	UserPermission.EditEntity,
];

UserRolePermissions[UserRoleEnum.Owner] = [
	...UserRolePermissions[UserRoleEnum.Administrator],
	UserPermission.DeleteEntity,
];
