export type CallbackFunction = () => any;

export interface PausableTimerOptions {
	autoStart: boolean;
	repeats: boolean;
	stepInterval: number;
}

const DEFAULT_OPTIONS: PausableTimerOptions = {
	autoStart: true,
	repeats: false,
	stepInterval: 250,
};

export class PausableTimer {
	repeats!: boolean;

	remaining!: number;
	timestamp!: number;
	stepInterval!: number;
	stepTimer!: number;

	constructor(
		public ms: number,
		public callback?: CallableFunction,
		options?: Partial<PausableTimerOptions>,
	) {
		const opts: PausableTimerOptions = {
			...DEFAULT_OPTIONS,
			...options,
		};

		this.repeats = opts.repeats;
		this.stepInterval = opts.stepInterval;

		if (opts.autoStart) {
			this.start();
		}
	}

	static setInterval(
		ms: number,
		callback?: CallableFunction,
		options?: Partial<PausableTimerOptions>,
	): PausableTimer {
		return new PausableTimer(ms, callback, { ...options, repeats: true });
	}

	static setTimeout(
		ms: number,
		callback?: CallableFunction,
		options?: Partial<PausableTimerOptions>,
	): PausableTimer {
		return new PausableTimer(ms, callback, { ...options, repeats: false });
	}

	start() {
		this.restart();
	}

	restart() {
		this.pause();
		this.remaining = this.ms;
		this.resume();
	}

	resume() {
		if (!this.remaining) this.restart();
		if (this.remaining <= 0) return;

		this.timestamp = new Date().getTime();
		this.stepTimer = window.setInterval(
			this._step.bind(this),
			this.stepInterval,
		);
	}

	clear() {
		this.pause();
		delete this.callback;
		this.remaining = 0;
	}

	pause() {
		if (this.stepTimer) clearInterval(this.stepTimer);
	}

	private _step() {
		const diff = new Date().getTime() - this.timestamp;

		this.timestamp = new Date().getTime();
		this.remaining = Math.max(0, this.remaining - diff);

		if (this.remaining > 0) return;

		clearInterval(this.stepTimer);

		if (this.callback) {
			this.callback();
		}

		if (this.repeats) {
			this.restart();
		}
	}
}

export default PausableTimer;
