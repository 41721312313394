var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"color":"error"}},[_c('v-toolbar-title',[_vm._v("Superadmin")])],1),_c('layout-sidenav',{attrs:{"sidenav-list":[
			{ to: "/admin/displays", label: "Displays" },
			"divider",
			{ to: "/admin/users", label: "Users" },
			"divider",
			{ to: "/admin/master/adsLarge", label: "Master - Advertisment - Large" },
			{ to: "/admin/master/adsSmall", label: "Master - Advertisment - Small" },
			{ to: "/admin/master/template", label: "Master - Template" } ]}},[_c('template',{slot:"content"},[_c('router-view')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }