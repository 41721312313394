export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;

export const RUTER_COLORS = {
	bus: {
		city: "#E60000",
		district: "#75A300",
		airport: "#949494",
	},
	tram: "#0B91EF",
	metro: "#EC700C",
	rail: "#003087",
	water: "#628C88",
};

export const TRANSIT_TIMES_THEME = {
	colors: RUTER_COLORS,
	iconSizes: {
		round: {
			width: "30",
			radius: "50",
		},
		small: {
			width: "36",
			radius: "40",
		},
		medium: {
			width: "43",
			radius: "32",
		},
		large: {
			width: "57",
			radius: "24",
		},
	},
};
