export enum ContentSlotEnum {
	ADS_LARGE = "adsLarge",
	ADS_SMALL = "adsSmall",
	BANNER = "banner",
	NOTE = "note",
	LOGO = "logo",
}

export type ContentSlotEnumMapType = {
	[key in ContentSlotEnum]: ContentSlotEnum;
};

export type ContentSlotEnumKeys = keyof typeof ContentSlotEnum;
