import { Field, ObjectType } from "@nestjs/graphql";

import { EntityInterface } from "../entity";
import { UserObject } from "../user";

@ObjectType()
export class InstallServerObject {
	@Field(() => UserObject)
	admin!: UserObject;

	@Field(() => EntityInterface)
	masterDisplay!: EntityInterface;

	@Field(() => EntityInterface)
	rootEntity!: EntityInterface;
}
