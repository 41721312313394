import { Field, Int, ObjectType } from "@nestjs/graphql";

@ObjectType("EntityTransitStop")
export class EntityTransitStopObject {
	@Field(() => Int)
	id!: number;

	@Field(() => Int)
	entityId!: number;

	@Field(() => String)
	stopId!: string;

	@Field(() => String)
	label!: string;
}
