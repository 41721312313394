
























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { Note } from "@/models/Note";

import EditNoteDialog from "./EditNoteDialog.vue";
import NoteBoardNote from "./Note.vue";

@Component({
	components: { EditNoteDialog, NoteBoardNote },
})
export default class NoteBoard extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	loading = true;
	showEditDialog = false;
	editNote: Note = new Note();

	get notes(): Note[] {
		return this.$store.getters.notesFor(this.entity);
	}

	get sortedNotes() {
		return this.notes.sort((a, b) => {
			const av = new Date(a.date).valueOf();
			const bv = new Date(b.date).valueOf();

			if (av > bv) return -1;
			if (av < bv) return 1;

			if (av === bv) {
				if (a.id > b.id) return -1;
				if (a.id < b.id) return 1;
			}

			return 0;
		});
	}

	get regularNotes() {
		return this.sortedNotes.filter((n) => !n.sticky);
	}

	get stickyNotes() {
		return this.sortedNotes.filter((n) => !!n.sticky);
	}

	get noteBoardBgColor() {
		return this.entity.noteBoardBackgroundColor || "#e6e6e6";
	}

	@Watch("entity", { immediate: true })
	async onEntityChange() {
		this.loading = true;
		await this.$store.dispatch("fetchNotes", { entity: this.entity });
		this.loading = false;
	}

	edit(note?: Note) {
		const date = new Date();

		date.setHours(0, 0, 0, 0);

		this.editNote =
			note ||
			new Note({
				date,
				entityId: this.entity.id,
			});
		this.showEditDialog = true;
	}
}
