













import { Component, Prop, Vue } from "vue-property-decorator";

import SdWeatherIcon from "../SdWeatherIcon";

@Component({
	components: { SdWeatherIcon },
})
export default class SdWeatherForecast extends Vue {
	@Prop({ required: true, type: [Number] })
	id!: number;

	@Prop({ required: true, type: [Number] })
	temp!: number;

	@Prop({ required: true, type: [String] })
	text!: string;

	@Prop({ default: false, type: [Boolean] })
	isNight!: boolean;
}
