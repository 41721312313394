






















import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class AppNavigationDrawer extends Vue {
	get isOpen(): boolean {
		return this.$store.getters.appDrawer;
	}

	set isOpen(value: boolean) {
		void this.$store.dispatch("setAppDrawer", value);
	}
}
