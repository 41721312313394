import { ActionTree } from "vuex";

import { EntityTransitStopObject } from "@scrinz/dtos";
import { EntityInterface } from "@scrinz/dtos";

import http from "@/http";
import { RootState } from "@/store";
import { TransitStopsState } from "./types";

const enturCategoryToMdIcon: { [category: string]: string } = {
	onstreetBus: "directions_bus",
	busStation: "directions_bus",
	onstreetTram: "tram",
	metroStation: "subway",
	railStation: "train",
	ferryStop: "directions_boat",
	harbourPort: "directions_boat",
	airport: "local_airport",
};

export const actions: ActionTree<TransitStopsState, RootState> = {
	async fetchTransitStops({ commit }, entity: EntityInterface) {
		const res = await http.get(`entities/${entity.id}/transit-stops`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to fetch transit stops.`);
		}

		commit("UPDATE_TRANSIT_STOPS", res.data);
	},

	async addTransitStop({ commit }, stop: EntityTransitStopObject) {
		const res = await http.post(
			`entities/${stop.entityId}/transit-stops`,
			stop,
		);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to add transit stop.`);
		}

		res.data.newlyCreated = true;

		commit("ADD_TRANSIT_STOP", res.data);
	},

	async deleteTransitStop({ commit }, stop: EntityTransitStopObject) {
		const res = await http.delete(
			`entities/${stop.entityId}/transit-stops/${stop.id}`,
		);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to delete transit stop.`);
		}

		commit("DELETE_TRANSIT_STOP", stop);
	},

	async searchTransitStops(_, query: string) {
		const res = await http.get(`transit/search-stops/?q=${query}`);

		if (!res || res.status !== 200) {
			throw new Error(
				`Failed to search for transit stops with query "${query}".`,
			);
		}

		return res.data
			.map((stop: any) => {
				stop["icons"] = [];

				stop.category.forEach((category: string) => {
					if (
						!enturCategoryToMdIcon[category] ||
						stop.icons.includes(enturCategoryToMdIcon[category])
					) {
						return;
					}

					stop.icons.push(enturCategoryToMdIcon[category]);
				});

				return stop;
			})
			.filter((stop: any) => {
				return stop.icons.length > 0;
			});
	},
};
