import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { SessionState } from "./types";

export const getters: GetterTree<SessionState, RootState> = {
	ability(state) {
		return state.ability;
	},

	hasSession(state) {
		return !!(state.token && state.tokenUser);
	},

	hasTimedOut(state) {
		return state.tokenUser && !state.token;
	},

	token(state) {
		return state.token;
	},

	tokenUser(state) {
		return state.tokenUser;
	},
};
