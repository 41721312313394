import { Field, ObjectType } from "@nestjs/graphql";
import { EntityInterface } from "./entity.interface";

@ObjectType("DisplayEntity", {
	implements: [EntityInterface],
})
export class DisplayEntityObject extends EntityInterface {
	@Field(() => Boolean)
	onlineStatus!: boolean;

	@Field(() => String)
	layoutId!: string;

	@Field(() => String)
	registrationHandle!: string;
}
