






























































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import clone from "lodash/clone";
import {
	get as getCookie,
	remove as removeCookie,
	set as setCookie,
} from "es-cookie";

import { EntityInterface } from "@scrinz/dtos";
// import Calendar from "@/components/Calendar";
import EditBannerDialog from "./EditBannerDialog.vue";
import SwimlaneView from "./SwimlaneView.vue";
// import BannerEditorSwimlane from "./Swimlane.vue";

import { Getter } from "vuex-class";

const DEFAULT_BANNER = {
	asset: null,
	start: new Date(),
	end: new Date(
		new Date(new Date(new Date().setHours(0)).setMinutes(0, 0)).valueOf() +
			1000 * 60 * 60 * 24 * 14,
	), // tslint:disable-line
	duration: 30,
};

@Component({
	components: {
		// Calendar,
		EditBannerDialog,
		SwimlaneView,
		// BannerEditorSwimlane,
	},
})
export default class BannerEditor extends Vue {
	@Getter contentsFor!: (entity: EntityInterface, slot: string) => any[];

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ default: "banner" })
	slotId!: string;

	@Prop()
	slotWidth!: number;

	@Prop()
	slotHeight!: number;

	// view: "swimlanes" | "calendar" = "swimlanes";
	view: string = getCookie("banner-editor-view") || "swimlanes";

	now = new Date();
	events: any[] = [];

	loading = true;
	showEditDialog = false;
	editBanner: any = clone(DEFAULT_BANNER);

	get upcomingEvents() {
		return this.events.filter((event) => {
			if (typeof event.isTemplate === "boolean" && event.isTemplate) {
				return false;
			}
			const end = event.end ? new Date(event.end) : null;
			return end === null ? true : end.valueOf() >= this.now.valueOf();
		});
	}

	get rowHeight() {
		return 30; // tslint:disable-line
	}

	@Watch("entity", { immediate: true })
	@Watch("slotId")
	async fetch() {
		this.loading = true;
		await this.$store.dispatch("fetchContents", {
			entity: this.entity,
			slot: this.slotId,
		});
		this.loading = false;
		this.update();
	}

	@Watch("view")
	updateViewCookie() {
		removeCookie("banner-editor-view");
		setCookie("banner-editor-view", this.view);
	}

	edit(banner: any = {}) {
		// (this.$refs["edit-banner-dialog"] as any).showDialog(banner || clone(DEFAULT_BANNER));

		this.editBanner = {
			...clone(DEFAULT_BANNER),
			...banner,
		};

		this.showEditDialog = true;
	}

	update() {
		this.events = this.contentsFor(this.entity, this.slotId);
	}

	async submit(data: any) {
		return this.$store.dispatch("updateEntity", data);
	}
}
