














import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SzDialog extends Vue {
	@Prop({ default: false })
	active!: boolean;

	@Prop({ default: 6 })
	szElevation!: number;

	@Prop({ default: true })
	closeOnClickOutside!: boolean;

	@Prop({ default: true })
	closeOnEsc!: boolean;

	close() {
		this.$emit("update:active", false);
	}

	open() {
		this.$emit("update:active", true);
	}

	destroyed() {
		this.unbindEventListeners();
	}

	@Watch("active", { immediate: true })
	onActiveStateChange() {
		if (this.active) {
			this.bindEventListeners();
		} else {
			this.unbindEventListeners();
		}
	}

	bindEventListeners() {
		document.addEventListener("click", this.onDocumentClick);
		document.addEventListener("keydown", this.onDocumentKeydown);
	}

	unbindEventListeners() {
		document.removeEventListener("click", this.onDocumentClick);
		document.removeEventListener("keydown", this.onDocumentKeydown);
	}

	onDocumentClick = (e: MouseEvent) => {
		if (!(e as any).path.includes(this.$vnode.elm) && this.closeOnClickOutside) {
			e.stopPropagation();
			e.preventDefault();
			this.close();
		}
	}

	onDocumentKeydown = (e: KeyboardEvent) => {
		if (e.code === "Escape" && this.closeOnEsc) {
			e.stopPropagation();
			e.preventDefault();
			this.close();
		}
	}
}
