import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
	const locales = require.context(
		"../locales",
		true,
		/[A-Za-z0-9-_,\s]+\.json$/i,
	);

	const messages: Record<string, any> = {};

	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});

	console.log(messages);

	return messages;
}

const i18n = new VueI18n({
	messages: loadLocaleMessages(),
	locale: process.env.VUE_APP_I18N_LOCALE || "no",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
});

export default i18n;
