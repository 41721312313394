var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"edit-note-dialog",attrs:{"persistent":_vm.isDirty,"max-width":800,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.item)?_c('sz-content-card',{attrs:{"title":_vm.$t(("components.note-board." + (_vm.open && _vm.item.id ? 'editNote' : 'newNote')))},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":"","pa-3":""}},[_c('sz-date-picker-input',{attrs:{"label":_vm.$t("Start date"),"prepend-icon":"calendar_today","min-date":_vm.today,"max-date":_vm.item.end},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1),_c('v-flex',{attrs:{"xs12":"","md6":"","pa-3":""}},[_c('sz-date-picker-input',{attrs:{"clearable":"","prepend-icon":"date_range","label":_vm.$t("End date"),"min-date":_vm.item.date},model:{value:(_vm.item.end),callback:function ($$v) {_vm.$set(_vm.item, "end", $$v)},expression:"item.end"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md9":"","pa-3":""}},[_c('v-flex',{attrs:{"xs9":""}},[_c('v-select',{staticClass:"icon-select",attrs:{"label":_vm.$t("Icon"),"prepend-icon":_vm.item.icon,"hide-details":"","menu-props":"auto","items":[
								{
									value: 'priority_high',
									text: _vm.$t('components.note-board.icons.exclamation'),
								},
								{
									value: 'comment',
									text: _vm.$t('components.note-board.icons.comment'),
								},
								{
									value: 'thumb_up_alt',
									text: _vm.$t('components.note-board.icons.thumb-up'),
								},
								{
									value: 'thumb_down_alt',
									text: _vm.$t('components.note-board.icons.thumb-down'),
								},
								{
									value: 'local_parking',
									text: _vm.$t('components.note-board.iconspParking'),
								},
								{
									value: 'warning',
									text: _vm.$t('components.note-board.icons.warning'),
								},
								{
									value: 'sentiment_satisfied_alt',
									text: _vm.$t('components.note-board.icons.smiley'),
								},
								{
									value: 'group',
									text: _vm.$t('components.note-board.icons.group'),
								},
								{
									value: 'build',
									text: _vm.$t('components.note-board.icons.wrench'),
								},
								{
									value: 'error_outline',
									text: _vm.$t('components.note-board.icons.error'),
								},
								{
									value: 'gavel',
									text: _vm.$t('components.note-board.icons.gavel'),
								},
								{
									value: 'alarm',
									text: _vm.$t('components.note-board.icons.alarm'),
								},
								{
									value: 'settings',
									text: _vm.$t('components.note-board.icons.gear'),
								},
								{
									value: 'i',
									text: _vm.$t('components.note-board.icons.information'),
									plain: true,
								} ]},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('v-list-item-content',{staticClass:"icon-select__item"},[_c('v-list-item-title',[_c('span',{staticClass:"icon-select__item__icon"},[(props.item.plain)?_c('span',{staticClass:"icon-select__item__icon__plain"},[_vm._v(_vm._s(props.item.value))]):_c('v-icon',[_vm._v(_vm._s(props.item.value))])],1),_c('span',{staticClass:"icon-select__item__text"},[_vm._v(_vm._s(props.item.text))])])],1)]}}],null,false,516380090),model:{value:(_vm.item.icon),callback:function ($$v) {_vm.$set(_vm.item, "icon", $$v)},expression:"item.icon"}})],1),_c('v-flex',{attrs:{"xs3":"","ml-2":""}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-btn',_vm._g({style:({
										'background-color': _vm.item && _vm.item.iconColor,
									})},on),[_vm._v(_vm._s(_vm.$t("color")))])]}}],null,true)},[_c('swatches',{model:{value:(_vm.item.iconColor),callback:function ($$v) {_vm.$set(_vm.item, "iconColor", $$v)},expression:"item.iconColor"}})],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","pa-3":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('sz-text-editor',{model:{value:(_vm.item.text),callback:function ($$v) {_vm.$set(_vm.item, "text", $$v)},expression:"item.text"}})],1)],1)]},proxy:true},{key:"actions-left",fn:function(){return [_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.open = false}}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('div',{staticClass:"save-state"},[(_vm.isFresh && !_vm.isDirty)?_c('div',[_c('span',[_vm._v("Empty note")])]):(_vm.isFresh)?_c('div',[_c('span',[_vm._v("New, unsaved note")]),_c('v-icon',[_vm._v("report_problem")])],1):(_vm.isDirty)?_c('div',[_c('span',[_vm._v("Unsaved changes")]),_c('v-icon',[_vm._v("report_problem")])],1):_c('div',[_c('span',[_vm._v("Saved")]),_c('v-icon',[_vm._v("check_circle")])],1)])]},proxy:true},{key:"actions-right",fn:function(){return [(_vm.item.id)?_c('v-select',{attrs:{"label":_vm.$t("Approval status"),"solo":"","prepend-icon":"visibility","hide-details":"","items":[
					{ value: false, text: _vm.$t("Pending approval") },
					{ value: true, text: _vm.$t("Published") } ]},model:{value:(_vm.item.approved),callback:function ($$v) {_vm.$set(_vm.item, "approved", $$v)},expression:"item.approved"}}):_vm._e(),(_vm.item.id)?_c('span',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("Update")))])],1):_c('span',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("Save as draft")))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save(true)}}},[_vm._v(_vm._s(_vm.$t("Publish")))])],1)]},proxy:true}],null,false,302145305)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }