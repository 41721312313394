



































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { EntityInterface } from "@scrinz/dtos";
import { GetOrganizationOfEntityFunction } from "@/store";
import http from "@/http";

import { DisplayOnlineStatus } from "@/components/Display";

@Component({
	components: { DisplayOnlineStatus },
})
export default class AdminDisplays extends Vue {
	@Getter
	getOrganizationOfEntity!: GetOrganizationOfEntityFunction;

	displays: any[] = [];
	selected: any[] = [];

	updateSnackbar = false;
	updateSnackbarColor = "";
	updateSnackbarText = "";

	get headers() {
		return [
			{ text: this.$t("ID"), value: "id", align: "left" },
			{
				text: this.$t("Organization"),
				value: "entity.parentId",
				align: "left",
			},
			{ text: this.$t("Name"), value: "entity.name", align: "left" },
			{ text: this.$t("Code word"), value: "entity.codeWord", align: "left" },
			{
				text: this.$t("Online"),
				value: "display.onlineStatus",
				align: "center",
			},
		];
	}

	getOrganizationName(entity: EntityInterface) {
		const org = this.getOrganizationOfEntity(entity);

		return org ? org.name : "-";
	}

	async created() {
		const res = await http.get("/admin/displays");

		this.displays = res.data.map((display: any) => {
			return {
				value: false,
				id: display.entity.id,
				...display,
			};
		});
	}

	async update(entities: "all" | any[]) {
		const confirmed = await this.$confirm(
			entities === "all"
				? this.$t(`routes.admin.displays.update.confirm.updateAll`)
				: this.$t(`routes.admin.displays.update.confirm.updateSelected`, {
						count: this.selected.length,
				  }),
			{
				title: this.$t(`routes.admin.displays.update.confirm.title`),
			},
		);

		if (!confirmed) return;

		const ids =
			entities === "all" ? "all" : entities.map((item) => item.entity.id);

		const res = await http.post("admin/displays/update", {
			entities: ids,
		});

		if (res.data.updated) {
			this.updateSnackbarText = this.$t(
				`routes.admin.displays.update.success`,
			) as string;
			this.updateSnackbarColor = "success";
		} else {
			this.updateSnackbarText = this.$t(
				`routes.admin.displays.update.error`,
			) as string;
			this.updateSnackbarColor = "error";
		}

		this.updateSnackbar = true;
		this.selected = [];
	}
}
