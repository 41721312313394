import { Field, ObjectType } from "@nestjs/graphql";
import { DisplayOrientationEnum } from "@scrinz/dtos";

import {
	DisplayEntityObject,
	EntityTransitStopObject,
	OrganizationEntityObject,
} from "../entity";
import { DisplayLayoutEnum } from "./display-layout.enum";
import { DisplayManifestFallbackObject } from "./display-manifest-fallback.object";
import { DisplayManifestModuleObject } from "./display-manifest-module.object";

@ObjectType("DisplayManifest")
export class DisplayManifestObject {
	@Field(() => String, { nullable: true })
	codeWord?: string | null;

	@Field(() => Boolean, { nullable: true })
	showCodeWord?: boolean;

	@Field(() => String, { nullable: true })
	timeZone?: string;

	@Field(() => String, { nullable: true })
	displayId?: string | null;

	@Field(() => String, { nullable: true })
	displayInformation?: string | null;

	// ---

	@Field(() => DisplayLayoutEnum, { nullable: true })
	layout?: DisplayLayoutEnum | null;

	@Field(() => DisplayOrientationEnum)
	orientation!: DisplayOrientationEnum;

	@Field(() => [DisplayManifestModuleObject])
	modules!: DisplayManifestModuleObject[];

	@Field(() => [DisplayManifestFallbackObject])
	fallback!: DisplayManifestFallbackObject[];

	// ---

	@Field(() => DisplayEntityObject)
	display!: DisplayEntityObject;

	@Field(() => OrganizationEntityObject)
	organization?: OrganizationEntityObject;

	@Field(() => [EntityTransitStopObject])
	transitStops!: EntityTransitStopObject[];
}
