






























import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import App from "./App.vue";
import LoginForm from "@/routes/Global/LoginForm.vue";

@Component({
	components: {
		App,
		LoginForm,
	},
})
export default class Main extends Vue {
	@Getter hasSession!: boolean;
	@Getter hasConnectivity!: boolean;
	@Getter hasTimedOut!: boolean;
	@Getter isTestingConnectivity!: boolean;

	@Action testConnectivity!: any; // TODO: Fix type.

	// Show modal if don't have serverconnectivity.
	get showConnectivityIssueModal() : boolean { return !this.hasConnectivity; }
	// Do nothing. Can't be closed before connectivity is re-established.
	set showConnectivityIssueModal(_value: boolean) {
		// TODO: Not implemented
	}

	// Show modal if session has timed out.
	get showSessionTimedOutModal() : boolean { return this.hasTimedOut; }
	// Do nothing. Can't be closed before re-authentication.
	set showSessionTimedOutModal(_value: boolean) {
		// TODO: Not implemented
	}

	// Retries the connection until one is established.
	async retryConnection() {
		await this.testConnectivity(true);
	}
}
