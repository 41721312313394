














import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import http from "@/http";

import AssetUploader from "@/components/AssetUploader";
import BannerEditor from "@/components/BannerEditor";

@Component<AdminMaster>({
	title: vm => `Master "${vm.slotId}"`,
	components: { AssetUploader, BannerEditor },
})
export default class AdminMaster extends Vue {
	@Prop({ required: true })
	slotId!: string;

	// asset: string = "";
	// contentId: number|undefined;

	entity: any = null;
	display: any = null;

	width: number|undefined;
	height: number|undefined;
	isSetup = false;

	@Watch("slotId", { immediate: true })
	async fetch() {
		// this.asset = "";
		// this.contentId = undefined;
		this.isSetup = false;
		this.entity = null;
		this.display = null;
		this.width = undefined;
		this.height = undefined;

		// tslint:disable:no-magic-numbers
		if (this.slotId === "adsSmall") {
			this.width = 480;
			this.height = 175;
		} else if (this.slotId === "adsLarge") {
			this.width = 590;
			this.height = 630;
		} else if (this.slotId === "template") {
			this.width = 480;
			this.height = 895;
		} else {
			throw new Error("Not a recognized slot id");
		}
		// tslint:enable:no-magic-numbers

		const master = await http.get("admin/master");

		this.entity = master.data.entity;
		this.display = master.data.display;

		this.isSetup = true;
	}
}
