




import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class BannerEditorSlotButton extends Vue {
	get classes() {
		return {
			"banner-editor__slot__button": true,
			"elevation-0": true,
		};
	}
}
