import { registerEnumType } from "@nestjs/graphql";

export enum ContentTypeEnum {
	BannerAsset = "banner-asset",
	BannerTemplate = "banner-template",
	Note = "note",
}

registerEnumType(ContentTypeEnum, {
	name: "ContentType",
	description: "The different content types supported.",
});
