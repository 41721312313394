import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { SessionState } from "./types";

export const state: SessionState = {
	ability: null,
	token: null,
	tokenUser: null,
};

export const manifest: Module<SessionState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default manifest;
