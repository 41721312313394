

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AutoApproveNumbersEmptyState extends Vue {
	@Prop({ required: true, type: [Function] })
	addFunction!: any;
}
