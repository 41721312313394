





























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import getenv from "getenv.ts";

import { loginRedirect } from "@/helpers";

const enum LoginState {
	Loaded,
	Submitting,
	LoggedIn,
	InvalidCredentials,
	ErrorOccured,
}

@Component({})
export default class LoginForm extends Vue {
	supportEmail: string = getenv.string("VUE_APP_CONTENT_SUPPORT_EMAIL");

	@Prop({ default: true })
	displayResetPasswordLink!: boolean;

	email = "";
	password = "";

	state: LoginState = LoginState.Loaded;

	get isInvalidCredentials() {
		return this.state === LoginState.InvalidCredentials;
	}

	async login() : Promise<any> {
		// Only submit once at a time.
		if (this.state === LoginState.Submitting) return null;
		this.state = LoginState.Submitting;

		try {
			const res = await this.$store.dispatch("login", {
				email: this.email,
				password: this.password,
			});

			if (!res) {
				this.state = LoginState.InvalidCredentials;

				return false;
			}

			this.state = LoginState.LoggedIn;

			await loginRedirect(this);
		} catch (err) {
			this.state = LoginState.ErrorOccured;
			alert("Unknown error occured. Please try again and report to admin.");
		}
	}
}
