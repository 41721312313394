















import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
	// title: "Change Password",
})
export default class ClientSettingsPassword extends Vue {
	async submit(data: any) {
		return this.$store.dispatch("changePassword", data);
	}
}
