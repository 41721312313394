import { registerEnumType } from "@nestjs/graphql";

export enum DisplayLayoutEnum {
	Default = "default",
	Varig = "varig",
}

registerEnumType(DisplayLayoutEnum, {
	name: "DisplayLayoutEnum",
});
