





















































































import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import store from "@/store";

import EntityAddDialog from "./AddDialog.vue";

@Component({
	components: { EntityAddDialog },
})
export default class EntityListItem extends Vue {
	@Prop({ default: true })
	enableDrag!: boolean;

	@Prop({ default: false, type: [Boolean, Number] })
	enableDrop!: boolean | number;

	@Prop({ default: true })
	enableInteraction!: boolean;

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface & { newlyCreated?: boolean; newlyUpdated?: boolean };

	dragging = false;
	dragImage!: Element;
	hover: boolean | number = false;
	showChildren = false;

	showAddDialog = false;

	get droppable() {
		return (
			this.enableDrop &&
			!this.dragging &&
			this.$scrinz.entityCanHaveChildren(this.entity)
		);
	}

	mounted() {
		setTimeout(
			() => {
				delete this.entity.newlyCreated;
				delete this.entity.newlyUpdated;
			},
			0, // tslint:disable-line:no-magic-numbers
		);

		// If is top level, or user isn't admin, display children.
		if (!this.entity.parent?.id || !this.$can("admin")) {
			this.showChildren = true;
		}
	}

	toggleChildren() {
		this.showChildren = !this.showChildren;
	}

	openAddEntityDialog() {
		this.showAddDialog = true;
	}

	onDragStart(event: DragEvent) {
		if (!this.enableDrag) return;
		if (!event.dataTransfer) return;

		// Stop from dagging parents.
		event.stopPropagation();

		// Set that this item is being dragged.
		this.dragging = true;

		// Emit id of entity dragging to parent.
		this.$emit("dragging", this.entity.id);

		// Create a better drag image.
		this.dragImage = (this.$el as any).cloneNode(true);
		this.dragImage.classList.add("drag-image");

		// Find children element.
		const children = Object.keys(this.dragImage.children)
			.map((k: any) => this.dragImage.children[k])
			.find((el) => el.classList.contains("children"));

		// Remove children if present.
		if (children) this.dragImage.removeChild(children);

		// Append drag image clone to wrapper.
		(this.$refs["drag-image-wrapper"] as any).appendChild(this.dragImage);

		// Set the drag image data transfer.
		event.dataTransfer.setDragImage(this.dragImage, 0, 0);

		// Remove the image as soon as possible, to clean up UI.
		setTimeout(() => {
			(this.$refs["drag-image-wrapper"] as any).removeChild(this.dragImage);
		}, 0);

		// Setup data transfer.
		event.dataTransfer.setData("text", JSON.stringify(this.entity));
	}

	onDragEnd(event: DragEvent) {
		// Should only handle this even.
		event.stopPropagation();

		// Set dragging to false.
		this.dragging = false;

		// Emit no longer dragging.
		this.$emit("dragging", false);

		// Emit was dropped.
		this.$emit("dropped", this.entity);
	}

	onDragOver(event: DragEvent) {
		if (!this.droppable) return;

		// Allow drop.
		event.preventDefault();
		event.stopPropagation();
		this.hover = this.entity.id;
	}

	onDragEnter(event: DragEvent) {
		if (!this.droppable) return;

		event.preventDefault();
		event.stopPropagation();
		this.hover = this.entity.id;
	}

	onDragLeave(event: DragEvent) {
		if (!this.droppable) return;

		event.stopPropagation();
		this.hover = false;
	}

	async onDrop(event: DragEvent) {
		// Ensure this element is droppable.
		// if (!this.isDroppable(entity)) return;
		if (!event.dataTransfer) return;
		if (!this.droppable) return;

		// Only drop once.
		event.stopPropagation();

		// Flag no longer draggin over.
		this.hover = false;

		// Convert data transfer to JSON.
		const data = JSON.parse(event.dataTransfer.getData("text"));

		// Update the parent id on the data.
		data.parentId = this.entity.id;

		// Dispatch to the store.
		await store.dispatch("updateEntity", data);
	}
}
