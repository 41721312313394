import { ScrinzAppFeature, ScrinzAppFeatureSet } from "./interfaces";

const DEFAULT_FEATURES: ScrinzAppFeatureSet = {
	adsLarge: { height: 630, width: 590 },
	adsSmall: { height: 175, width: 480 },
	banner: { height: 895, width: 480 },
	partnerLogo: { enabled: false, height: 80, width: 536 },
};

const FEATURE_SETS: { [key: string]: ScrinzAppFeatureSet } = {
	styretavla: {
		adsLarge: true,
		adsSmall: true,
		banner: true,
		partnerLogo: false,
	},
	varig: {
		adsLarge: false,
		adsSmall: false,
		banner: { height: 1080, width: 1080 },
		partnerLogo: true,
	},
};

export const FEATURE_SET = process.env.VUE_APP_FEATURE_SET;

if (!FEATURE_SET || !(FEATURE_SET in FEATURE_SETS)) {
	throw new Error(`VUE_APP_FEATURE_SET is misconfigured ("${FEATURE_SET}")`);
}

export const FEATURES = FEATURE_SETS[FEATURE_SET];

export function getFeature<T extends ScrinzAppFeature>(
	key: string,
): T | false | void {
	if (!(key in FEATURES)) return;
	let feature = (FEATURES as any)[key];
	if (feature === undefined) return;
	if (feature === true) {
		feature = (DEFAULT_FEATURES as any)[key];
		feature.enabled = true;
	}
	if (feature === false || feature.enabled === false) return false;
	return feature;
}
