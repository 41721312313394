import { registerEnumType } from "@nestjs/graphql";

export enum DisplayOrientationEnum {
	Horizontal = "horizontal",
	Vertical = "vertical",
}

registerEnumType(DisplayOrientationEnum, {
	name: "DisplayOrientationEnum",
});
