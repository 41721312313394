var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":600},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('sz-content-card',{attrs:{"title":_vm.$t('components.entity.delete-dialog.title', _vm.entity)}},[_c('v-alert',{attrs:{"type":"warning","value":true}},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.entity.delete-dialog.alert.strong")))]),_vm._v(" "+_vm._s(_vm.$t("components.entity.delete-dialog.alert.text"))+" ")]),_c('v-card-text',[(_vm.hasChildren)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("components.entity.delete-dialog.children.intro")))]),_c('p',[_c('v-select',{attrs:{"solo":"","hide-details":"","label":_vm.$t("components.entity.delete-dialog.children.select.label"),"items":[
							{
								value: 'delete',
								text: _vm.$t(
									"components.entity.delete-dialog.children.select.options.delete"
								),
							},
							{
								value: 'move',
								text: _vm.$t(
									"components.entity.delete-dialog.children.select.options.move"
								),
							} ]},model:{value:(_vm.withChildren),callback:function ($$v) {_vm.withChildren=$$v},expression:"withChildren"}})],1),(_vm.withChildren === 'delete')?_c('p',[_c('v-checkbox',{attrs:{"color":"error","hide-details":"","label":_vm.$t(
								"components.entity.delete-dialog.children.delete.confirmLabel"
							)},model:{value:(_vm.confirmDeleteChildren),callback:function ($$v) {_vm.confirmDeleteChildren=$$v},expression:"confirmDeleteChildren"}})],1):_vm._e(),(_vm.withChildren === 'move')?_c('div',[(_vm.moveToEntities.length > 0)?_c('p',[_c('v-select',{attrs:{"solo":"","hide-details":"","label":_vm.$t(
									"components.entity.delete-dialog.children.move.selectLabel"
								),"items":_vm.moveToEntities,"item-text":"name","item-value":"id"},model:{value:(_vm.moveTo),callback:function ($$v) {_vm.moveTo=$$v},expression:"moveTo"}})],1):_c('p',[_vm._v(" "+_vm._s(_vm.$t( "components.entity.delete-dialog.children.move.noValidTarget" ))+" ")])]):_vm._e()]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.$t("components.entity.delete-dialog.instructions.preInput"))+" "),_c('sz-input',{directives:[{name:"sz-autosize",rawName:"v-sz-autosize"},{name:"sz-click-select",rawName:"v-sz-click-select"}],staticClass:"caution",attrs:{"value":_vm.entity.name,"readonly":"","plain":"","inline-block":""}}),_vm._v(" "+_vm._s(_vm.$t("components.entity.delete-dialog.instructions.postInput"))+" ")],1),_c('p',[_c('v-text-field',{attrs:{"solo":"","hide-details":"","label":_vm.$t("components.entity.delete-dialog.confirmationInput.label")},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}})],1)]),_c('template',{slot:"actions-left"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(_vm._s(_vm.$t("components.entity.delete-dialog.cancelButtonText")))])],1),_c('template',{slot:"actions-right"},[_c('v-btn',{attrs:{"color":"error","disabled":!_vm.confirmationIsValid},on:{"click":_vm.deleteEntity}},[_vm._v(_vm._s(_vm.$t("components.entity.delete-dialog.deleteButtonText")))])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }