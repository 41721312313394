





























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { isSdComponentServiceInterface } from "../utils";

import SdWeatherIcon from "../SdWeatherIcon";
import SdWeatherForecast from "./SdWeatherForecast.vue";
import SdWeatherToday from "./SdWeatherToday.vue";
import {
	SdWeatherData,
	SdWeatherDayData,
	SdWeatherServiceInterface,
} from "./interfaces";

@Component({
	components: { SdWeatherForecast, SdWeatherIcon, SdWeatherToday },
	i18n: {
		messages: {
			en: {
				now: "Now",
				tomorrow: "Tomorrow",
			},
			no: {
				now: "Nå",
				tomorrow: "I morgen",
			},
		},
	},
})
export default class SdWeather<
	Data extends SdWeatherData | SdWeatherServiceInterface = SdWeatherData
> extends Vue {
	/**
	 * A service to be used to update weather content.
	 */
	@Prop({ required: true, type: [Object] })
	weather!: Data;

	/**
	 * Weather data to display as current.
	 */
	current: SdWeatherDayData | null = null;

	/**
	 * Weather forecast data to display for tomorrow.
	 */
	tomorrow: SdWeatherDayData | null = null;

	/**
	 * Weather forecast data to display for the day after tomorrow.
	 */
	dayAfterTomorrow: SdWeatherDayData | null = null;

	get isMinusDegrees() {
		return (this.current?.temp || 0) < 0;
	}

	get isNight() {
		const time = new Date();
		return time.getHours() < 6;
	}

	get dayAfterTomorrowDate(): Date {
		const afterTomorrow = new Date();
		afterTomorrow.setDate(afterTomorrow.getDate() + 2);
		return afterTomorrow;
	}

	created() {
		if (!isSdComponentServiceInterface(this.weather)) return;
		this.weather.addListener(this.onDataChanged);
	}

	beforeDestroy() {
		if (!isSdComponentServiceInterface(this.weather)) return;
		this.weather.removeListener(this.onDataChanged);
	}

	// @Watch("weather", { immediate: true })
	// onWeatherChanged = (data: Data, oldData: Data) => {
	// 	if (data !== oldData && isSdComponentServiceInterface(oldData))
	// 		oldData.removeListener(this.onDataChanged);
	// 	if (!isSdComponentServiceInterface(data))
	// 		return this.onDataChanged(data as SdWeatherData);
	// 	else data.addListener(this.onDataChanged);
	// }

	onDataChanged(data: SdWeatherData) {
		this.current = data.current;
		this.tomorrow = data.tomorrow;
		this.dayAfterTomorrow = (data as any).dayAfter;
	}
}
