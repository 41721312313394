

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import store from "@/store";

@Component
export default class EntityBreadcrumbs extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ default: false })
	isParent!: boolean;

	get parent() {
		return store.getters.getEntity(this.entity.parent?.id);
	}

	get parents() {
		const parents: EntityInterface[] = [];
		let current = this.entity;

		while (current.parent?.id) {
			current = store.getters.getEntity(current.parent.id);
			if (!current) break;
			parents.unshift(current);
		}

		return parents;
	}
}
