


























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import clone from "lodash/clone";

import { AutoApproveNumber } from "@/store";

@Component
export default class AutoApproveNumbersEditDialog extends Vue {
	@Prop({ default: false })
	value!: boolean;

	@Prop({ type: [Object] })
	number!: AutoApproveNumber | null;

	item: AutoApproveNumber | null = null;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	@Watch("number", { immediate: true })
	onNumberChange() {
		this.item = clone(this.number);
	}

	async submit(data: AutoApproveNumber) {
		const num: AutoApproveNumber = {
			...this.item,
			...data,
		};

		await this.$store.dispatch(
			num.id ? "updateAutoApproveNumber" : "createAutoApproveNumber",
			num,
		);

		this.isOpen = false;
		this.onNumberChange();
	}
}
