
































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import Input from "./Input.vue";
import SzChildWrapper from "../ChildWrapper.vue";
import SzDialog from "../Dialog.vue";
import SzOption from "./Option.vue";
import SzOptionGroup from "./OptionGroup.vue";

@Component({
	components: { SzChildWrapper, SzDialog, SzOption },
})
export default class SzSelect extends Input {
	@Prop({ default: () => ({}) })
	options!: { [key: string]: string };

	isOpen = false;
	slottedOptions: { [key: string]: string } = {};
	observer!: MutationObserver;

	get displayValue() {
		const value = this.szValue as any;

		if (value === undefined) return this.placeholder;

		return this.slottedOptions[value] || this.options[value] || value || this.placeholder;
	}

	async mounted() {
		this.observer = new MutationObserver(this.findOptions.bind(this));
		this.observer.observe((this.$refs.options as Vue).$el, {
			attributes: true,
			characterData: true,
			childList: true,
			subtree: true,
		});

		await this.findOptions();
	}

	@Watch("isOpen")
	@Watch("options", { deep: true })
	async findOptions() {
		await this.$nextTick();

		this.slottedOptions = {};
		this._findOptions((this.$refs.options as Vue));
	}

	select(value: string) {
		this.szValue = value;
		this.isOpen = false;
	}

	protected _findOptions(el: Vue) {
		if (!el || !el.$children) return;

		for (const option of el.$children) {
			if (option instanceof SzOptionGroup) {
				this._findOptions(option);
				continue;
			}

			if (!(option instanceof SzOption)) return;

			this.slottedOptions[option.value] = option.getLabel();
			option.$on("selected", () => {
				this.select(option.getValue());
			});
		}
	}
}
