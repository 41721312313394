























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class StyretavlaLogo extends Vue {
	@Prop({ default: 120 })
	width!: number;

	@Prop({ default: "#17b25d" })
	primaryColor!: string;

	@Prop({ default: "#ffffff" })
	secondaryColor!: string;

	@Prop({ default: "#ff5252" })
	accentColor!: string;

	colors: { [key: string]: string } = {};
	protected _temp: any = {};

	get svgStyle() {
		return {
			width: `${this.width}px`,
		};
	}

	get primaryFillStyle() {
		return {
			fill: this.colors.primary,
		};
	}

	get secondaryFillStyle() {
		return {
			fill: this.colors.secondary,
		};
	}

	get secondaryStrokeStyle() {
		return {
			fill: "none",
			stroke: this.colors.secondary,
		};
	}

	@Watch("primaryColor", { immediate: true })
	@Watch("secondaryColor")
	@Watch("accentColor")
	updateColor() {
		this.colors = {
			accent: this.accentColor,
			primary: this.primaryColor,
			secondary: this.secondaryColor,
		};
	}

	hover() {
		this._temp = { ...this.colors };

		this.colors.primary = this._temp.secondary;
		this.colors.secondary = this._temp.primary;
	}

	out() {
		this.colors.primary = this._temp.primary;
		this.colors.secondary = this._temp.secondary;

		this._temp = {};
	}
}
