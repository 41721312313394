






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class AdminUserList extends Vue {
	get headers() {
		return [
			{ text: this.$t("ID"), value: "id", align: "left" },
			{ text: this.$t("Email"), value: "email", align: "left" },
			{ text: this.$t("Name"), value: "name", align: "left" },
			{ text: this.$t("Phone"), value: "phoneNumber", align: "left" },
		];
	}

	get users() {
		return this.$store.getters.users;
	}
}
