import { NavigationGuard } from "vue-router";

import { routeRequiresAuth } from "@/helpers";
import store from "@/store";

export const IsLoggedInGuard: NavigationGuard = (to, _from, next) => {
	// Ensure we have a token.
	if (store.getters.token) {
		next();
	}

	// Redirect to `global--error-404` if route is `error-404` (from application),
	// but no token exists. We want to individualize the different 404 errors,
	// depending on where in the hierarchy the user is.
	else if (to.name === "client--error-404") {
		next({ name: "global--error-404", params: { 0: to.path } });
	}

	// Return next if current route don't require authentication.
	else if (!routeRequiresAuth(to)) {
		next();
	}

	// Return a redirect to login if a route was found, but no token.
	else {
		next({
			name: "auth--login",
			query: {
				// Ensure to.path isn't logout, to avoid redirect loop on login.
				return: (to.name !== "auth--logout" && to.path) || "",
			},
		});
	}
};

export default IsLoggedInGuard;
