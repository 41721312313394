
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Swatches from "@/components/ColorPicker/Swatches.vue";

@Component({
	components: { Swatches },
})
export default class SzColorSwatches extends Vue {
	@Prop()
	value!: string;

	@Prop()
	label!: string;

	@Prop()
	palette!: string[][];

	szValue = "";
	showMenu = false;

	@Watch("value", { immediate: true })
	protected _onValueChange(): void {
		this.szValue = this.value;
	}

	@Watch("szValue")
	protected _onSzValueChange(): void {
		this.$emit("input", this.szValue);
	}
}
