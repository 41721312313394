
















import Vue from "vue";
import { Component } from "vue-property-decorator";

import LayoutFluid from "@/layouts/Fluid.vue";

@Component({
	components: { LayoutFluid },
})
export default class LayoutFluidCard extends Vue {
	get hasTitle() {
		return !!this.$slots.title;
	}
}
