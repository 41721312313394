







import { Component, Vue } from "vue-property-decorator";

@Component
export default class SdClock extends Vue {
	/**
	 * The date to be displayed, set internally to the current timestamp of the
	 * client, updated every 5000ms.
	 */
	date!: Date;

	/**
	 * Internal reference to the timeout handler, in order to be able to clear the
	 * timeout handler on subsequent requests to avoid parallel calls.
	 */
	private _updateTimeout: number | undefined;

	/**
	 * Vue `created` lifecycle hook, just makes the first call to `_updateDate`.
	 */
	created() {
		this._updateDate();
	}

	/**
	 * Private method to update the date object. Calls itself after a 5000ms
	 * timeout.
	 */
	private _updateDate() {
		// Clears the previous timeout to avoid double calls.
		clearTimeout(this._updateTimeout);

		// Sets date to a new Date timestamp.
		this.date = new Date();

		// Forces an update of the view (since Vue doesn't register the update).
		this.$forceUpdate();

		// Sets a timeout to call `_updateDate` again in 5000ms.
		this._updateTimeout = window.setTimeout(this._updateDate.bind(this), 5000);
	}
}
