




import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SdWeatherIcon extends Vue {
	/**
	 * The status id as defined by Open Weather Maps.
	 */
	@Prop({ required: true, type: [Number, String] })
	status!: number | string;

	/**
	 * Flag whether to use day or night icon.
	 */
	@Prop({ default: false })
	isNight!: boolean;

	get dayOrNight() {
		return this.isNight ? "night" : "day";
	}
}
