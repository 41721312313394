import { MutationTree } from "vuex";

import { UsersState } from "./types";
import { EntityInterface } from "@scrinz/dtos";

// tslint:disable:naming-convention
export const mutations: MutationTree<UsersState> = {
	RESET_STATE(state) {
		state.users = [];
	},

	ADD_USER(state, user) {
		state.users.push(user);
	},

	DELETE_USER(state, user) {
		const idx = state.users.findIndex((u) => u.id === user.id);

		if (idx === -1) return;

		state.users.splice(idx, 1);
	},

	REMOVE_USER_PERM(
		state,
		payload: {
			entity: EntityInterface;
			user: any;
		},
	) {
		const user = state.users.find((u) => u.id === payload.user.id);

		if (!user) return;

		const idx = user.permissions.findIndex(
			(p: any) => p.entityId === payload.entity.id,
		);

		if (idx === -1) return;

		user.permissions.splice(idx, 1);
	},

	UPDATE_USER(state, user) {
		const current = state.users.find((u) => u.id === user.id);

		if (!current) return;

		Object.assign(current, user);
	},

	UPDATE_USER_PERMS(state, { user, permissions }) {
		const currentUser = state.users.find((u) => u.id === user.id);

		if (!currentUser) return;

		const currentPerms = currentUser.permissions.find(
			(p: any) => p.entityId === permissions.entityId,
		);

		Object.assign(currentPerms, permissions);
	},

	UPDATE_USERS(state, users: any[]) {
		for (const user of users) {
			const current = state.users.find((u) => u.id === user.id);

			if (current) {
				Object.assign(current, user);
			} else {
				state.users.push(user);
			}
		}
	},
};
