import { registerEnumType } from "@nestjs/graphql";

export enum AssetTypeEnum {
	Unknown,
	Image,
	Pdf,
}

registerEnumType(AssetTypeEnum, {
	name: "AssetType",
});
