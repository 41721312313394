import { Field, InputType } from "@nestjs/graphql";

import { AddContentBannerInput } from "./add-content-banner.input";

@InputType()
export class AddContentBannerTemplateInput extends AddContentBannerInput {
	@Field()
	template!: string;

	@Field()
	text!: string;
}
