








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SzOption extends Vue {
	@Prop({ default: null, type: [Array, Boolean, Object, Number, String] })
	value!: any;

	@Prop()
	label!: string;

	@Prop({ default: false })
	unset!: boolean;

	getLabel() {
		return this.label || (this.$refs.option as any).innerText;
	}

	getValue() {
		if (this.unset) return;
		if (typeof this.value === "boolean") return this.value;

		return this.value || this.getLabel();
	}

	select() {
		this.$emit("selected", this.getValue());
	}
}
