












import { Component, Prop, Vue } from "vue-property-decorator";

import { getTemplateById } from "./getTemplateById";

function getObjectValue(
	key: string | null,
	obj: Record<string, string>,
): string | null {
	if (key === null) return null;

	const [first, ...rest] = key.split(".");

	if (first in obj) {
		if (typeof obj[first] === "object") {
			return getObjectValue(rest.join("."), obj[first] as any);
		}

		return obj[first];
	}

	return null;
}

@Component
export default class SdBannerContainer extends Vue {
	@Prop({ required: true })
	template!: string;

	@Prop({ default: () => ({}) })
	templateVariables!: any;

	@Prop({ default: "" })
	text!: string;

	get preppedHtml() {
		if (!this.text) return "";

		const globalVariableRe = /\{\{[^}]*\}\}/g;
		const matches = this.text.match(globalVariableRe);

		if (!matches) return this.text;

		let out = this.text;
		const variableNameRe = /\{\{\s*([^\s}]*)\s*\}\}/;

		for (const match of matches) {
			const [, variableName] = match.match(variableNameRe) || [null, null];
			const replacement = getObjectValue(variableName, this.templateVariables);

			if (!replacement) continue;

			out = out.replace(match, replacement);
		}

		return out;
	}

	get imageUrl() {
		const template = getTemplateById(this.template);

		return template ? template.image : "";
	}

	get textClass() {
		const template = getTemplateById(this.template);

		return {
			"sd-banner-container__text": true,
			"sd-banner-container__text--light":
				!template || template.fontColor === "light" ? true : false,
			"sd-banner-container__text--dark": template
				? template.fontColor === "dark"
				: false,
		};
	}
}
