import { ActionTree } from "vuex";

import { AssetDto, EntityInterface } from "@scrinz/dtos";
import http from "@/http";
import { RootState } from "@/store";
import { AssetsState } from "./types";

export const actions: ActionTree<AssetsState, RootState> = {
	async fetchAsset({ commit, getters }, id: string) {
		const res = await http.get(`assets/${id}`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to load assets data for id "${id}".`);
		}

		commit("UPDATE_ASSET", res.data);

		return getters.getAsset(res.data.id);
	},

	async uploadAsset(
		{ commit, getters },
		{ entity, file }: { entity: EntityInterface; file: File },
	) {
		const data = new FormData();
		data.append("entity", (entity.id as any) as string);
		data.append("file", file, file.name);

		const res = await http.post(`assets/upload`, data);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to upload asset.`);
		}

		commit("UPDATE_ASSET", res.data.asset);

		return getters.getAsset(res.data.asset.id);
	},

	async updateAsset({ commit, getters }, asset: AssetDto) {
		const res = await http.put(`assets/${asset.id}`, asset);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update asset.`);
		}

		commit("UPDATE_ASSET", res.data);

		return getters.getAsset(res.data.id);
	},

	async saveCroppedAsset(_, asset: AssetDto & { cropped: Blob }) {
		const data = new FormData();
		data.append("file", asset.cropped, `${asset.id}-cropped`);

		const res = await http.put(`assets/${asset.id}/cropped`, data);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to save cropped asset image.`);
		}

		// commit("UPDATE_ASSET", res.data);

		// return getters.getAsset(res.data.id);
	},
};
