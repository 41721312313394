






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DebugRoute extends Vue {
	@Prop() someProp!: string;

	mounted() {
		console.log(this.$props);
	}
}
