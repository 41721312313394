import { FeatureCategory } from "@entur/sdk";
import { Field, ObjectType } from "@nestjs/graphql";

import { BaseDto } from "../base.dto";

@ObjectType("TransitStop")
export class TransitStopObject extends BaseDto {
	@Field(() => [FeatureCategory], { nullable: true })
	category?: FeatureCategory[];

	@Field(() => String, { nullable: true })
	label?: string | null;

	@Field(() => String, { nullable: true })
	name?: string | null;

	@Field(() => String)
	stopId!: string;
}
