











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import clone from "lodash/clone";

import { TransitStopObject } from "@scrinz/dtos";
import { EntityInterface } from "@scrinz/dtos";

import SearchInput from "./SearchInput.vue";

@Component({
	components: { SearchInput },
})
export default class TransitTimesStopSelectorAddDialog extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ type: [Object] })
	stop!: TransitStopObject | null;

	item: TransitStopObject | null = null;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	@Watch("stop", { immediate: true })
	onStopChange() {
		this.item = clone(this.stop);
	}

	async onStopSelected(stop: TransitStopObject) {
		await this.$store.dispatch("addTransitStop", {
			...stop,
			entityId: this.entity.id,
		});

		this.isOpen = false;
	}
}
