





























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { DisplayManifestObject, DisplayOrientationEnum } from "@scrinz/dtos";
import { SdNote } from "../SdNote";

export enum NoteBoardNotesAnimationDirection {
	Forward = 1,
	Backward = -1,
}

function hexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

@Component({
	components: {
		SdNote,
	},
})
export default class SdNoteBoardNotes extends Vue {
	@Prop({ required: true, type: [Object] })
	manifest!: DisplayManifestObject;

	@Prop({ required: true })
	notes!: any[];

	internalNotes: any[] = [];
	regularNotes: any[] = [];
	stickyNotes: any[] = [];

	timeout!: any;
	rotationTiming = 2000 * 10;

	get orientation() {
		return this.manifest.orientation;
	}

	get classes() {
		return {
			"sd-note-board-notes": true,
			"sd-note-board-notes--horizontal":
				this.orientation === DisplayOrientationEnum.Horizontal,
			"sd-note-board-notes--vertical":
				this.orientation === DisplayOrientationEnum.Vertical,
		};
	}

	get shadowStyles() {
		const styles: Record<string, string> = {};
		const bgColor =
			this.manifest.display.noteBoardBackgroundColor ||
			this.manifest.organization?.noteBoardBackgroundColor;
		const rgb = hexToRgb(bgColor || "#e0d6d0");

		if (rgb) {
			styles.background = `linear-gradient(
				to top,
				rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1) 0%,
				rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0) 100%
			)`;
		}

		return styles;
	}

	getWrapperElement() {
		return this.$refs.wrapper as HTMLElement;
	}

	getNotesElements() {
		return this.$refs.notes as Vue[];
	}

	getTotalVerticalSpace() {
		const wrapper = this.getWrapperElement();

		return [wrapper.clientHeight + 10, wrapper.clientHeight + 10].splice(
			0,
			this.orientation === DisplayOrientationEnum.Vertical ? 2 : 1,
		);
	}

	getNotesHeights() {
		const notes = this.getNotesElements();

		if (!notes) return [];

		return notes.map((n) => (n.$el.clientHeight as number) + 10);
	}

	isOverflowing() {
		const space = this.getTotalVerticalSpace();
		const heights = this.getNotesHeights();

		while (heights.length > 0) {
			if (space.length === 0) return true;

			if (space[0] - heights[0] < 0) {
				space.shift();
				continue;
			}

			space[0] = space[0] - heights[0];
			heights.shift();
		}

		return false;
	}

	zIndexForIndex(index: number) {
		if (!this.internalNotes) return 0;

		return this.internalNotes.length - index;
	}

	@Watch("notes", { immediate: true })
	async onNotesChange() {
		this.internalNotes = [...this.notes];

		this.internalNotes.map((note: any) => {
			if (note.sticky) {
				this.stickyNotes.push(note);
			} else {
				this.regularNotes.push(note);
			}
		});

		await this.$nextTick();

		if (this.isOverflowing()) {
			await this.primeNextRotation();
		}
	}

	async primeNextRotation() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		const fn = async () => {
			await this.rotate();
		};

		this.timeout = setTimeout(fn, 20000);
	}

	async rotate() {
		const regularFirst = this.regularNotes.shift();

		await this.$nextTick();

		this.regularNotes.push(regularFirst);

		await this.primeNextRotation();
	}
}
