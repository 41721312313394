import { IsBoolean, IsEnum, IsNumber, IsString } from "class-validator";

import { BaseDto } from "..";
import { AssetTypeEnum } from "./asset-type.enum";

export class AssetDto extends BaseDto {
	@IsString() id!: string;
	@IsString() name!: string;
	@IsString() mime!: string;
	@IsNumber() bytes!: number;
	@IsEnum(AssetTypeEnum) type!: AssetTypeEnum;

	@IsNumber() page!: number;
	@IsNumber() offsetX!: number;
	@IsNumber() offsetY!: number;
	@IsNumber() scale!: number;
	@IsBoolean() hasCropped!: boolean;
}
