








































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class UserMenu extends Vue {
	@Getter user!: any;
}
