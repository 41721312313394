import Hashids from "hashids";

const INSTANCES: { [type: string]: Hashids } = {
	display: new Hashids("display", 10),
	asset: new Hashids("asset", 20),
};

function ensureInstance(type: string) {
	if (!INSTANCES[type]) INSTANCES[type] = new Hashids(type);
}

export function encodeHashid(type: string, ...args: any[]) {
	ensureInstance(type);
	return INSTANCES[type].encode(...args);
}

export function decodeHashid(type: string, hash: string) {
	ensureInstance(type);
	return INSTANCES[type].decode(hash);
}
