import { Field, InputType } from "@nestjs/graphql";
import { IsEmail, IsString } from "class-validator";

@InputType()
export class InstallServerInput {
	@Field(() => String)
	@IsEmail()
	adminEmail!: string;

	@Field(() => String)
	@IsString()
	adminPassword!: string;

	@Field(() => String)
	@IsString()
	rootEntity!: string;
}
