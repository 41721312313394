import { IsBoolean, IsString } from "class-validator";

import { BaseDto } from "..";

export class ChangePasswordRequestDto extends BaseDto {
	@IsString() password!: string;
	@IsString() newPassword!: string;
	@IsString() confirmNewPassword!: string;
}

export class ChangePasswordResponseDto extends BaseDto {
	@IsBoolean() passwordChanged!: boolean;
}
