
























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityInterface, EntityTypeEnum } from "@scrinz/dtos";

const EMPTY_ENTITY = { name: "", type: "" };

@Component
export default class EntityAddDialog extends Vue {
	types = EntityTypeEnum;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [EntityInterface, Object] })
	parentEntity!: EntityInterface;

	entity = EMPTY_ENTITY;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	async submit(data: any) {
		await this.$store.dispatch("createEntity", {
			name: data.name,
			type: data.type,
			parentId: this.parentEntity.id,
		});

		this.entity = EMPTY_ENTITY;
		this.isOpen = false;
	}
}
