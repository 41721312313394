

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import clone from "lodash/clone";

import { EntityInterface } from "@scrinz/dtos";
import { AutoApproveNumber } from "@/store";

import AutoApproveNumbersEditDialog from "./EditDialog.vue";
import AutoApproveNumbersEmptyState from "./EmptyState.vue";
import AutoApproveNumbersList from "./List.vue";

@Component({
	components: {
		AutoApproveNumbersEditDialog,
		AutoApproveNumbersEmptyState,
		AutoApproveNumbersList,
	},
})
export default class AutoApproveNumbersCard extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	showEditDialog = false;
	editNumber: AutoApproveNumber = this.EMPTY_NUMBER;

	// tslint:disable-next-line
	get EMPTY_NUMBER(): AutoApproveNumber {
		return clone({
			entityId: this.entity.id,
			number: "",
			description: "",
		});
	}

	get isEmpty() {
		return this.numbers.length === 0;
	}

	get numbers(): AutoApproveNumber[] {
		return this.$store.getters.autoApproveNumbersFor(this.entity);
	}

	async mounted() {
		await this.$store.dispatch("fetchAutoApproveNumbers", this.entity);
	}

	edit(num?: AutoApproveNumber) {
		this.editNumber = num || this.EMPTY_NUMBER;
		this.showEditDialog = true;
	}
}
