


































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import LayoutFluid from "@/layouts/Fluid.vue";
import { FEATURES } from "@/features";

export interface LayoutSidenavItem {
	can?: string;
	feature?: string;
	to?: string;
	label: string;
	icon: string;
}

@Component({
	components: { LayoutFluid },
})
export default class LayoutSidenav extends Vue {
	@Prop({ default: true })
	breadcrumbs!: boolean;

	@Prop({ default: [] })
	sidenavList!: any[];

	@Prop()
	sidenavTitle!: string;

	@Prop({ type: [Boolean] })
	sidenavForceIconSlot!: boolean;

	get classes() {
		return {
			"layout-sidenav": true,
			"layout-sidenav--desktop": this.$vuetify.breakpoint.mdAndUp,
			"layout-sidenav--mobile": this.$vuetify.breakpoint.smAndDown,
		};
	}

	get hasSidenavTitle() {
		return !!this.$slots["sidenav-title"] || this.sidenavTitle;
	}

	get showBreadcrumbs() {
		return !!this.$slots.breadcrumbs && this.breadcrumbs;
	}

	isDivider(item: any) {
		return item === "divider" || item.type === "divider";
	}

	shouldShow(item: LayoutSidenavItem | string) {
		if (typeof item === "string") {
			return item === "divider";
		}

		if ("can" in item && !this.$can(item.can)) return false;
		if (item.feature && !(FEATURES as any)[item.feature]) return false;

		return true;
	}
}
