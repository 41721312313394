















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SdSpinnerIndeterminate extends Vue {
	/**
	 * The size (in percentage) the spinner should be.
	 */
	@Prop({ type: Number, default: () => 100 })
	size!: number;

	/**
	 * The thickness the spinner should have.
	 */
	@Prop({ type: Number, default: () => 3.6 })
	thickness!: number;

	/**
	 * Returns an object of styles representing the dimensions to use.
	 */
	get dimensions() {
		return {
			width:
				this.size < 10 ||
				this.size > 100 ?
				"100%" :
				`${Number(this.size)}%`
		};
	}

	get strokeWidth() {
		return Number(this.thickness);
	}

	mounted() {
		this.setStrokeWidth();
	}

	setStrokeWidth() {
		const spinnerPath = this.$refs["spinner-path"] as SVGCircleElement;
		const attrKey = "stroke-width";

		if ((spinnerPath.hasAttribute(attrKey) && (
		Number(spinnerPath.getAttribute(attrKey)) < .1 || Number(spinnerPath.getAttribute(attrKey)) > 16
		)) || !spinnerPath.hasAttribute(attrKey)) {
			spinnerPath.setAttribute(attrKey, this.strokeWidth.toString());
			spinnerPath.setAttribute("r", ((44 - this.strokeWidth) / 2).toString());
		}
	}
}
