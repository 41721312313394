var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sz-dialog-card-form',{attrs:{"max-width":400,"data":_vm._data,"submit":_vm.submit,"title":_vm.$t("components.users.add-dialog.title"),"fields":[
		{ key: 'email', label: _vm.$t("Email") },
		{
			key: 'role',
			label: _vm.$t("Role"),
			type: 'select',
			options: [
				{ value: 'owner', text: _vm.$t('Owner') },
				{ value: 'administrator', text: _vm.$t('Administrator') },
				{ value: 'moderator', text: _vm.$t('Moderator') } ],
		} ]},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})}
var staticRenderFns = []

export { render, staticRenderFns }