

































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import clone from "lodash/clone";

import { Note } from "@/models/Note";
import Swatches from "@/components/ColorPicker/Swatches.vue";

@Component({
	components: {
		Swatches,
	},
})
export default class EditNoteDialog extends Vue {
	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [Object] })
	note!: Note;

	item: Note | null = null;
	initialItem: Note | null = null;
	today = new Date();

	get open() {
		return this.value;
	}

	set open(value: boolean) {
		this.$emit("input", value);
	}

	get isFresh() {
		return this.item && !this.item.id;
	}

	get isDirty() {
		if (!this.item || !this.initialItem) return false;

		const keys = Object.keys(this.item);

		for (const key of keys) {
			const item = this.item[key];
			const initialItem = this.initialItem[key];

			if (item instanceof Date && initialItem instanceof Date) {
				if (item.getTime() !== initialItem.getTime()) return true;
				continue;
			}

			if (item !== initialItem) return true;
		}

		return false;
	}

	@Watch("note", { immediate: true })
	onNoteChange() {
		this.item = {
			...{
				entityId: null,
				text: "",
				icon: null,
				iconColor: null,
				date: null,
				end: undefined,
				sticky: undefined,
			},
			...clone(this.note),
		};

		this.initialItem = clone(this.item);
	}

	async save(publish = false) {
		if (!this.item) return;

		if (publish) {
			this.item.approved = true;
		}

		// if (this.item.sticky && !this.item.end) this.item.end = moment.add(7, "days").calendar();

		await this.$store.dispatch(
			this.item.id ? "updateNote" : "createNote",
			this.item,
		);

		this.open = false;
	}
}
