var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"client-entity-details-dashboard"},[_c('sz-content-card',{attrs:{"title":_vm.$t("General information")}},[_c('template',{slot:"toolbar-actions"},[(_vm.$scrinz.isDisplay(_vm.entity))?_c('v-btn',{attrs:{"color":"primary","href":_vm.previewLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("Preview display"))+" ")]):_vm._e()],1),_c('template',{slot:"content"},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('dd',[_c('sz-input',{directives:[{name:"sz-autosize",rawName:"v-sz-autosize"},{name:"sz-click-select",rawName:"v-sz-click-select"}],attrs:{"value":_vm.entity.name,"readonly":"","plain":""}})],1),_c('dt',[_vm._v(_vm._s(_vm.$t("Type")))]),_c('dd',[_c('sz-input',{directives:[{name:"sz-autosize",rawName:"v-sz-autosize"},{name:"sz-click-select",rawName:"v-sz-click-select"}],attrs:{"value":_vm.$t(_vm.$scrinz.getEntityTypeName(_vm.entity)),"readonly":"","plain":""}})],1),_c('dt',[_vm._v(_vm._s(_vm.$t("SMS code word")))]),_c('dd',[_c('sz-input',{directives:[{name:"sz-autosize",rawName:"v-sz-autosize"},{name:"sz-click-select",rawName:"v-sz-click-select"}],attrs:{"value":_vm.entity.codeWord,"placeholder":_vm.$t('No code word set for {0}', [
								_vm.$t(_vm.$scrinz.getEntityTypeName(_vm.entity)).toLowerCase() ]),"readonly":"","plain":"","monospace":!!_vm.entity.codeWord}})],1),_c('dt',[_vm._v(_vm._s(_vm.$t("SMS phone number")))]),_c('dd',[_c('sz-input',{directives:[{name:"sz-autosize",rawName:"v-sz-autosize"},{name:"sz-click-select",rawName:"v-sz-click-select"}],attrs:{"value":_vm.smsNumber,"placeholder":_vm.$t('No sms number set for {0}', [
								_vm.$t(_vm.$scrinz.getEntityTypeName(_vm.entity)).toLowerCase() ]),"readonly":"","plain":"","monospace":!!_vm.smsNumber}})],1)])])],2),(_vm.$can('admin') && _vm.$scrinz.isDisplay(_vm.entity))?_c('sz-content-card',{attrs:{"title":_vm.$t("Display information")}},[_c('template',{slot:"content"},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("Display ID")))]),_c('dd',[_c('sz-input',{directives:[{name:"sz-autosize",rawName:"v-sz-autosize"},{name:"sz-click-select",rawName:"v-sz-click-select"}],attrs:{"value":_vm.hashid,"readonly":"","plain":"","monospace":""},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
							var input = ref.input;
return [_c('sz-clipboard-button',{attrs:{"target":input.inputField}})]}}],null,false,3949958619)})],1),_c('dt',[_vm._v(_vm._s(_vm.$t("API key")))]),_c('dd',[_c('sz-input',{directives:[{name:"sz-autosize",rawName:"v-sz-autosize"},{name:"sz-click-select",rawName:"v-sz-click-select"}],staticClass:"apiKey",attrs:{"value":_vm.entity.apiKey,"placeholder":_vm.$t('No API key set for display'),"readonly":"","plain":"","monospace":!!_vm.entity.apiKey},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
							var input = ref.input;
return [(_vm.entity.apiKey)?_c('sz-clipboard-button',{attrs:{"target":input.inputField}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.regenerateApiKey}},on),[_c('v-icon',[_vm._v("autorenew")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Regenerate API key")))])])]}}],null,false,1803915554)})],1)])])],2):_vm._e(),(_vm.$can('admin') && _vm.$scrinz.entityCanHaveChildren(_vm.entity))?_c('sz-content-card',{staticClass:"child-entities",attrs:{"title":_vm.$t("Child entities of {name}", _vm.entity)}},[_c('template',{slot:"content"},[_c('entity-list',{attrs:{"entity":_vm.entity,"enable-interaction":false}})],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }