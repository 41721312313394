




















import { Component, Prop, Vue } from "vue-property-decorator";
import { TinyColor } from "@ctrl/tinycolor";

const defaultColors = [
	["#d3d3d3"],
	["#fd5f5c", "#e1a9a8"],
	["#354a77", "#6f8da7"],
	["#3d6938", "#91ba74"],
	["#1f96ac", "#5ec0c9"],
];

@Component
export default class Swatches extends Vue {
	@Prop({ default: () => defaultColors, type: Array })
	palette!: string[][];

	@Prop()
	value!: string;

	get color() {
		return this.value;
	}

	get pick() {
		return this.color;
	}

	equal(color: string): boolean {
		if (!color || !this.color) return false;

		return color.toLowerCase() === this.color.toLowerCase();
	}

	handleClick(color: any) {
		this.colorChange(color);
	}

	colorChange(color: any) {
		color = new TinyColor(color);
		color.setAlpha(1);

		if (!color.isValid) return;

		this.$emit("input", color.toHexString().toUpperCase());
	}
}
