











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SzContentCard extends Vue {
	@Prop()
	title!: string;

	@Prop()
	titleExtraClass!: string;

	@Prop()
	hideActions!: boolean;

	@Prop({ default: false })
	collapsible!: boolean;

	@Prop({ default: false })
	collapsed!: boolean;

	isCollapsed = false;

	@Watch("collapsed", { immediate: true })
	onCollapsedChange() {
		this.isCollapsed = this.collapsed;
	}

	get isCollapsible() {
		return !!this.collapsible;
	}

	get componentClass() {
		return {
			"sz-content-card": true,
			"sz-content-card--collapsed": this.isCollapsed,
		};
	}

	get titleClass() {
		const cls: { [cls: string]: boolean } = {
			"sz-content-card__toolbar__title": true,
		};

		if (this.titleExtraClass) {
			cls[this.titleExtraClass] = true;
		}

		return cls;
	}

	get hasActions() {
		return (
			!!this.$slots.actions ||
			!!this.$slots["actions-left"] ||
			!!this.$slots["actions-right"]
		);
	}

	get hasTitle() {
		return !!this.$slots.title || !!this.title;
	}

	get hasToolbar() {
		return !!this.$slots.toolbar;
	}

	get hasToolbarOrTitle() {
		return this.hasToolbar || this.hasTitle;
	}

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
	}
}
