import { Route } from "vue-router";

export function routeRequiresInstall(route: Route) {
	return (
		(route.matched &&
			route.matched.length > 0 &&
			route.matched.some((r) => r.meta && r.meta.requiresInstall)) ||
		false
	);
}
