
























import Vue from "vue";
import { Component } from "vue-property-decorator";

import LayoutSidenav from "@/layouts/Sidenav.vue";

@Component({
	components: { LayoutSidenav },
})
export default class Admin extends Vue {}
