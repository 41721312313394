import { Field, Int, InterfaceType } from "@nestjs/graphql";
import { EntityInterface } from "../entity/entity.interface";
import { UserObject } from "../user";

import { ContentTypeEnum } from "./content-type.enum";

@InterfaceType({
	isAbstract: true,
	description:
		"Interface describing the base properties for all content types.",
	resolveType(content: ContentInterface) {
		switch (content.type) {
			case ContentTypeEnum.BannerAsset:
				return "ContentBannerAsset";
			case ContentTypeEnum.BannerTemplate:
				return "ContentBannerTemplate";
			case ContentTypeEnum.Note:
				return "ContentNote";
			default:
				throw new TypeError("failed to resolve entity type");
		}
	},
})
export class ContentInterface {
	@Field(() => Int)
	id!: number;

	@Field(() => EntityInterface)
	entity!: EntityInterface;

	@Field(() => UserObject, { nullable: true })
	user!: UserObject | null;

	@Field(() => Boolean, { nullable: true })
	approved!: boolean | null;

	@Field()
	slot!: string;

	@Field(() => ContentTypeEnum)
	type!: ContentTypeEnum;

	@Field(() => Date)
	start!: Date;

	@Field(() => Date, { nullable: true })
	end!: Date | null;
}
