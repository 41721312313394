
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import SzInput from "./Input.vue";

@Component
export default class SzField extends Vue {
	@Prop()
	label!: string;

	@Prop({ default: "above" })
	labelPlacement!: "above" | "left";

	@Prop({ default: true })
	fillInput!: boolean;

	@Prop()
	icon!: string;

	hasFocus = false;
	inputClasses: string[] = [];

	get fieldClasses() {
		return [
			`label-placement-${this.labelPlacement}`,
			this.fillInput ? `fill-input` : undefined,
			...this.inputClasses,
		];
	}

	get szInput(): SzInput | undefined {
		return this.szInputs.length > 0 ? this.szInputs[0] : undefined;
	}

	get szInputs(): SzInput[] {
		if (!this.$slots.default) return [];

		return this.$slots.default
			.filter(slot => slot.componentInstance && "isSzInput" in slot.componentInstance)
			.map(slot => slot.componentInstance as SzInput)
			;
	}

	async mounted() {
		this.szInputs.forEach(input => {
			const updateClasses = (...args: any[]) => {
				this.updateClasses(input, ...args);
			};

			(input as any).disableOutline = true;
			input.$on("focus", updateClasses);
			input.$on("blur", updateClasses);
			input.$on("input", updateClasses);
		});
	}

	focus() {
		if (!this.$slots.default) return;

		this.$slots.default.forEach(slot => {
			if (slot && slot.componentInstance && (slot.componentInstance as any).focus) {
				(slot.componentInstance as any).focus();
			}
		});
	}

	updateClasses(input: SzInput, ..._args: any[]) {
		this.inputClasses = (input as any).modifierClasses;
	}
}
