import { registerEnumType } from "@nestjs/graphql";

export enum UserRoleEnum {
	Owner = "owner",
	Administrator = "administrator",
	Moderator = "moderator",
}

registerEnumType(UserRoleEnum, {
	name: "UserRole",
});
