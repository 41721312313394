























import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import PausableTimer from "./PausableTimer";

@Component
export default class SdSlideTimer extends Vue {
	@Prop({ default: 0 })
	slide!: number;

	@Prop({ required: true })
	numSlides!: number;

	@Prop({ default: 7500 })
	speed!: number;

	@Prop({ default: false })
	paused!: boolean;

	activeSlide = -1;
	started = false;
	timer!: PausableTimer;

	@Watch("slide", { immediate: true })
	async onSlideChange(_new: number, old: number) {
		if (this.slide === old) return;

		this.started = false;

		if (this.timer) {
			this.timer.clear();
		}

		await this.$nextTick();

		this.timer = PausableTimer.setInterval(this.speed, this.next.bind(this), {
			autoStart: !this.paused,
		});

		this.started = true;

		await this._setSlide(this.slide);
	}

	@Watch("paused")
	async onPausedChange() {
		if (this.paused) this.timer.pause();
		else this.timer.resume();
	}

	async next() {
		await this._setSlide(
			(this.activeSlide + 1 >= this.numSlides)
			? 0
			: this.activeSlide + 1,
		);
	}

	private async _setSlide(slide: number) {
		if (this.activeSlide === slide) return;

		this.activeSlide = slide;
		this.$emit("slide-changed", slide);
	}
}
