import { Field, InputType } from "@nestjs/graphql";
import { ContentTypeEnum } from "../content-type.enum";

@InputType()
export class GetContentInput {
	@Field(() => Boolean, { nullable: true })
	includeParents?: boolean;

	@Field(() => Boolean, { nullable: true, defaultValue: () => true })
	approved?: boolean | null;

	@Field(() => String, { nullable: true })
	slot?: string;

	@Field(() => ContentTypeEnum, { nullable: true })
	type?: ContentTypeEnum;
}
