



















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import store from "@/store";
import { EntityDeleteDialog } from "@/components/Entity";
import TransitTimesStopSelector from "@/components/TransitTimesStopSelector";

import SettingsFeatures from "@/routes/Client/EntityDetails/Settings/Features.vue";

@Component<ClientEntityDetailsSettings>({
	title: vm => ["Entity Settings", vm.entity.name],
	components: {
		EntityDeleteDialog,
		SettingsFeatures,
		TransitTimesStopSelector,
	},
})
export default class ClientEntityDetailsSettings extends Vue {
	@Prop({ required: true })
	id!: string;

	showDeleteDialog = false;

	get entity() {
		return store.getters.getEntity(this.id);
	}

	async submit(data: any) {
		return this.$store.dispatch("updateEntity", data);
	}
}
