





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import SdWeatherIcon from "../SdWeatherIcon";

@Component({
	components: { SdWeatherIcon },
})
export default class SdWeatherToday extends Vue {
	@Prop({ required: true, type: [Number] })
	id!: number;

	@Prop({ required: true, type: [Number] })
	temp!: number;

	@Prop({ default: false, type: [Boolean] })
	isNight!: boolean;

	get isMinusDegrees() {
		return (this.temp || 0) < 0;
	}
}
