import { SdComponentServiceInterface } from "./interfaces";

export function isSdComponentServiceInterface(
	test: unknown,
): test is SdComponentServiceInterface<any> {
	if (typeof test !== "object" || !test) return false;
	if ("data" in test && "addListener" in test && "removeListener" in test)
		return true;
	return false;
}
