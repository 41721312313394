






















import { Component, Vue } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

@Component
export default class ClientSettingsLanguage extends Vue {
	get user() {
		return this.$store.getters.user;
	}

	async submit(data: any) {
		i18n.locale = data.language;

		return this.$store.dispatch("updateUser", data);
	}
}
