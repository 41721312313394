























import { Component, Prop, Vue } from "vue-property-decorator";

import { SdClock } from "../SdClock";
import {
	SdTransitTimes,
	SdTransitTimesData,
	SdTransitTimesServiceInterface,
} from "../SdTransitTimes";
import {
	SdWeather,
	SdWeatherData,
	SdWeatherServiceInterface,
} from "../SdWeather";
import { isSdComponentServiceInterface } from "../utils";

import SdServiceModulesDivider from "./SdServiceModulesDivider.vue";

@Component({
	components: {
		SdClock,
		SdServiceModulesDivider,
		SdTransitTimes,
		SdWeather,
	},
})
export default class SdServiceModules extends Vue {
	@Prop({ default: false })
	clockDisabled!: boolean;

	@Prop({ default: null })
	transitTimes!: SdTransitTimesData | SdTransitTimesServiceInterface;

	@Prop({ default: false })
	transitTimesDisabled!: boolean;

	@Prop({ default: null })
	weather!: SdWeatherData | SdWeatherServiceInterface;

	@Prop({ default: false })
	weatherDisabled!: boolean;

	get showClock() {
		return this.clockDisabled !== true;
	}

	hasTransitTimesData = false;
	get showTransitTimes() {
		if (this.transitTimesDisabled) return false;
		return this.hasTransitTimesData;
	}

	get showWeather() {
		return this.weather && this.weatherDisabled !== true;
	}

	mounted() {
		if (isSdComponentServiceInterface(this.transitTimes)) {
			const times = this.transitTimes;
			times.addListener(() => {
				if (times.data) this.hasTransitTimesData = true;
			});
		} else {
			this.hasTransitTimesData = !!this.transitTimes;
		}
	}
}
