






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import NoteBoard from "@/components/NoteBoard";

@Component<ClientEntityDetailsNotes>({
	title: vm => ["Notes", vm.entity.name],
	components: { NoteBoard },
})
export default class ClientEntityDetailsNotes extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}
}
