






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import BannerEditor from "@/components/BannerEditor";

@Component<ClientEntityDetailsTemplates>({
	title: (vm) => ["Templates", vm.entity.name],
	components: { BannerEditor },
})
export default class ClientEntityDetailsTemplates extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}
}
